import React from 'react';

const ErrorInfo = (props) => {
  let info='未知错误，请联系管理员。'

  switch( props.error ){
    case 'required':
      info = '请输入必填项'
      break;
    case 'json_format_error':
      info = 'JSON格式错误'
      break;
  }

  return (
    <div className="error_info">{info}</div>
  )
}

export default ErrorInfo;