import React from 'react';
import _ from 'lodash';
import FAI from '../fontawesome.js'
import { Button } from './form'


export default class ListBuilder extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  on_sort_change(col) {
    if (col.sort_id) {
      let order = 'asc'
      if (this.props.order[col.sort_id]) {
        order = this.props.order[col.sort_id].toLowerCase() === 'asc' ? 'desc' : 'asc'
      }
      this.props.on_order_change(col.sort_id, order)
    }
  }

  render() {

    const config_da = []

    let max_width = 0
    for (let k in this.props.config) {
      config_da.push({
        key: k,
        ...this.props.config[k]
      })
      if (typeof this.props.config[k].width === 'number') {
        max_width += this.props.config[k].width
      }
    }

    return (
      <table className="list_table">
        <colgroup>
          <col width="10" />
          {config_da.map( c => {
            let col_w = c.width
            if( typeof c.width === 'number' ){
              col_w = parseInt( c.width/max_width * 100 ) + '%'
            }
            return (<col key={c.key} width={col_w} />)
          } )}
          <col width="60" />
        </colgroup>
        <thead>
          <tr>
            <th className="col__cb"><input type="checkbox" /></th>
            {config_da.map( c => {
              let sort_icon
              const sortable = c.sort_id ? true : false
              if( c.sort_id && this.props.order[c.sort_id] ){
                sort_icon = this.props.order[c.sort_id] === 'asc' ? <FAI icon="sort-amount-down-alt" /> : <FAI icon="sort-amount-down" />
              }
              return (<th key={c.key} className={'col_' + c.key + (sortable ? ' sortable' : '')} onClick={( evt )=>{ this.on_sort_change(c) }}>{c.label} {sort_icon}</th>)
            } )}
            <th className="col__ops">操作</th>
          </tr>
        </thead>
        <tbody>
          { this.props.list.map( i => {
            return (
              <tr key={i.id}>
                <td className="col__cb"><input type="checkbox" /></td>
                {config_da.map( c => {
                  const col_v = c.value
                  let i_v 
                  if( typeof col_v === 'string' ){
                    i_v = _.get(i,col_v) || ''
                  }else if( typeof col_v === 'function' ){
                    i_v = col_v( i )
                  }
                  return (<td className={"col_" + c.key} key={c.key}>{i_v}</td>)
                } )}
                <td className="col__ops no_wrap">
                  <Button className="btn delete sm" icon="times" onClick={()=>{ this.props.on_delete(i) }}>删除</Button>
                  <Button className="btn primary sm" icon="edit" onClick={()=>{ this.props.on_edit(i) }}>编辑</Button>
                </td>
              </tr>
              )
          } ) }
        </tbody>
      </table>
    );
  }
}
