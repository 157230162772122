import React from 'react';
import FAI from '../fontawesome.js'

const Pagi = (props) => {
  // const pagi_padding = 3
  // let pagi_max = props.page + pagi_padding
  // pagi_max = pagi_max > props.total ? props.total : pagi_max
  // let pagi_min = props.page - pagi_padding
  // pagi_min = pagi_min < 1 ? 1 : pagi_min
  
  // const has_first_page = pagi_min == 1 ? false : true
  // const has_last_page = pagi_max == props.total ? false : true
  
  const is_first_page = props.page == 1 ? true : false
  const is_last_page = props.page == props.total ? true : false

  const pagis = []
  for( let i = 1; i <= props.total; i++ ){
    pagis.push( i )
  }
  
  const onChange = function( i ){
    if( i != props.page ){
      props.onChange(i)
    }
  }

  const pageSelected = function(evt){
    props.onChange(evt.target.value);
  }

  return (
    <div className="pagi_nav">
      <div className="info">
        <button><span>总数:{props.count}</span></button>
      </div>

      <div className="nav">
        <button disabled={is_first_page} onClick={()=>{onChange(1)}}><i><FAI icon="angle-double-left" /></i><span></span></button>
        <button disabled={is_first_page} onClick={()=>{onChange(props.page - 1)}}><i><FAI icon="angle-left" /></i><span></span></button>
        <select value={props.page} onChange={pageSelected}>
          {pagis.map( i => <option key={i} value={i}>{i} / {props.total}</option> )}
        </select>
        <button disabled={is_last_page} onClick={()=>{onChange(props.page+1)}}><span></span><i><FAI icon="angle-right" /></i></button>
        <button disabled={is_last_page} onClick={()=>{onChange(props.total)}}><span></span><i><FAI icon="angle-double-right" /></i></button>
      </div>
    </div>
  )
}

export default Pagi;