import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import LoadingDots from '../../widgets/LoadingDots.jsx'

import IdsObjects from '../../widgets/form/IdsObjects'

import FAI from '../../fontawesome.js'

const component_map = {
  // 'Objects' : Objects,
  // 'Dashboard' : Dashboard,
  // 'UserProfile' : UserProfile,
  'ids_objects': IdsObjects,
}



function msp(state) {
  return {
    menu: state.menu
  };
}

function mdp(dispatch) {
  return {
    // list: function (object, criteria = [], page = 1, size = 10, order = {}) {
    //   dispatch(ObjectAction.list(object, criteria, page, size, order))
    // },
  }
}


class Playground extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      if (!this.state.is_side_menu_hide) {}
    }
  }

  renderBackend(route) {

    console.log(route.location.pathname)
    console.log(route)

    const component_key = _.get(route, 'match.params.component', null);
    console.log(`component_key ${component_key}`)
    return null
  }

  render() {
    return (
      <div className="layout layout_backend">
        <div className="page page_backend">
          <div className="page_main">
            <div className="__loadingdots_test size_1">
              ABC 123
              <LoadingDots />
            </div>
            <Switch>
              <Route path="/playground/:component" render={this.renderBackend.bind(this)} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}


export default connect(
  msp, mdp
)(Playground)
