import React from 'react';
import { connect } from 'react-redux';
import HeaderDropDown from './HeaderDropDown.jsx'
// import UserAction from '../redux/actions/user.js'
import FAI from '../fontawesome.js'
import Logout from '../widgets/Logout.jsx'
import { Button } from '../widgets/form/index.js'

function msp(state) {
  return {
    user:state.user
  };
}

// function mdp(dispatch) {
//   return {
//     logout:() => {
//       dispatch(UserAction.logout())  
//     }
//   }
// }

export class Header extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header>
        <div className="logo">
          <img src="/images/logo.png" /> 定制礼品管理系统
        </div>
        <h2></h2>
        <div className="user_menu">
          <HeaderDropDown icon="user" title={null}>
            <p>名称：{this.props.user.name}</p>
            <p>角色：{this.props.user.role.name}</p>
            <p>邮箱：{this.props.user.email}</p>
            
            <Logout><Button className="btn delete sm">退出</Button></Logout>
          </HeaderDropDown>
        </div>
      </header>
    );
  }
}

export default connect(
  msp,
  // mdp
)(Header)
