import React from 'react';
import _ from 'loadsh';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { Button } from './form'
import dialogActions from '../redux/actions/dialog';

function msp(state) {
  return {
    dialog: state.dialog,
  };
}

function mdp(dispatch) {
  return {
    show_dialog: (config) => {
      dispatch(dialogActions.show(config))
    },
    hide_dialog: () => {
      dispatch(dialogActions.hide())
    },
  }
}

export class Dialog extends React.Component {

  constructor(props) {
    super(props);
  }

  on_confirm() {
    this.setState({
      show: false
    })
  }

  on_close() {
    this.setState({
      show: false
    })
  }

  componentDidMount() {}

  componentDidUpdate(prev_props, prev_state) {
    console.log('componentDidUpdate')
    // console.log( 'prev_props' )
    // console.log( prev_props )
    // console.log( 'prev_state' )
    // console.log( prev_state )
  }


  render() {
    const dialog = this.props.dialog
    console.log('render dialog')
    console.log(dialog)

    let content = dialog.content
    if (dialog.type === 'custom') {
      content = this.props.children
    }


    let btns = []

    if (_.isArray(dialog.btns) && dialog.btns.length) {
      btns = dialog.btns;
    } else {
      if (_.isFunction(dialog.on_confirm)) {
        btns.push({
          label: '确定',
          cls: 'btn md primary',
          callback: _ => {
            dialog.on_confirm( this.props.hide_dialog.bind(this) )
          },
        })
      }

      if (_.get(dialog, 'on_cancel', false)) {
        btns.push({
          label: '关闭',
          cls: 'btn md delete',
          callback: this.props.hide_dialog.bind(this),
        })
      }
    }

    const el_btns = btns.map((i, idx) => {
      return <Button key={idx} className={i.cls} onClick={i.callback}>{i.label}</Button>
    })

    let render_obj = null
    if (dialog.show) {
      render_obj = (
        <section className={ 'fe_dialog ' + dialog.type }>
          <div className="con">
            <div className="header">{dialog.title}</div>
            <div className="body">{content}</div>
            <div className="btns">{el_btns}</div>
          </div>
        </section>
      )
    }

    return render_obj

  }
}

export default connect(
  msp, mdp
)(Dialog)
