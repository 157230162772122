import _ from 'lodash';

export default (state = {}, { type, payload }) => {
  let new_state = { ...state }
  switch (type) {
    case 'DIALOG_SHOW':
      new_state = _.pick(payload, [
        'uuid', //uuid of the dialog
        'type', //dialog|prompt|custom
        'title', //标题
        'content', //内容，如果type=custom，则使用props.children
        'btns', //如果定义，则使用按钮
        'on_confirm', //回调事件
        'on_cancel' //回调事件
      ]);
      new_state.show = true
      // console.log( 'new_state' )
      // console.log( new_state )
      return new_state
    case 'DIALOG_HIDE':
      new_state = {
        show:false,
        uuid:null,
      }
      return new_state
    default:
      return state
  }
}
