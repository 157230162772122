import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faBars,
  faPlus,
  faCircle,
  faUser,
  faKey,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faCubes,
  faCog,
  faCogs,
  faEdit,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faSortAmountDown,
  faSortAmountDownAlt,
  faGift,
  faGifts,
  faTachometerAlt,
  faShoppingCart,
  faList,
  faFolderOpen,
  faFile,
  faFileImage,
  faSwatchbook,
  faUsers,
  faUserTag,
  faUserCheck,
  faIdCard,
  faDna,
  faHome,
  faSlidersH,
  faAddressCard,
  faTasks,
  faTags,
  faTag,
  faFilter,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faPlus,
  faCircle,
  faUser,
  faKey,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faCubes,
  faCog,
  faCogs,
  faEdit,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faSortAmountDown,
  faSortAmountDownAlt,
  faGift,
  faGifts,
  faTachometerAlt,
  faShoppingCart,
  faList,
  faFolderOpen,
  faFile,
  faFileImage,
  faSwatchbook,
  faUsers,
  faUserTag,
  faUserCheck,
  faIdCard,
  faDna,
  faHome,
  faSlidersH,
  faAddressCard,
  faTasks,
  faTags,
  faTag,
  faFilter,
)

export default FontAwesomeIcon