import React from 'react'
import API from '../../../../api.js'


export default {
  'name': {
    tab: '基本信息',
    label: '名称',
    type: 'text',
    type_params: {},
    require: true, //是否必填
  },
  'key': {
    tab: '基本信息',
    label: 'key',
    type: 'text',
    type_params: {},
    require: true, //是否必填
  },
  'option_ids': {
    tab: '基本信息',
    label: '选项',
    type: 'ids_objects',
    width: 'full',
    type_params: {
      object_key: 'wc_option_item',
      object_label: '选项',
      add_fields: {
        'name': {
          label: '名称',
          type: 'text',
          type_params: {},
          require: true, //是否必填
        },
        'key': {
          label: 'key',
          type: 'text',
          type_params: {},
          require: true, //是否必填
        },
      },
      edit_fields: {
        'name': {
          label: '名称',
          type: 'text',
          type_params: {},
          require: true, //是否必填
        },
        'key': {
          label: 'key',
          type: 'text',
          type_params: {},
          require: true, //是否必填
        },
      },
      list_fields: {
        'id': {
          label: 'id',
          width: '50px',
          value: 'id',
        },
        'name': {
          label: '名称',
          width: 10,
          value: 'name',
        },
        'key': {
          label: 'key',
          width: 10,
          value: 'key',
        },
      },
      list_template: 'wc_option',
    },
  },

  // 'option_ids':{
  //   tab: '基本信息',
  //   label: '选项',
  //   type:'checkboxes',
  //   type_params:{
  //     options:function( _this ){
  //       return API.get_object_options('permission')
  //     }
  //   }
  // },

  // 'default_option_id':{
  //   tab: '基本信息',
  //   label: '默认选项',
  //   type:'radios',
  //   type_params:{
  //     options:function( _this ){
  //       return API.get_object_options('permission')
  //     }
  //   }
  // },

  // 'default_option_id':{
  //   tab: '基本信息',
  //   label: '默认选项',
  //   type:'obj_picker',
  //   type_params:{
  //     options:function( _this ){
  //       return API.get_object_options('permission')
  //     },
  //     list_template: 'wc_option',
  //   }
  // },

  'desc': {
    tab: '基本信息',
    label: 'desc',
    type: 'text',
    type_params: {},
  },
}
