import API from '../../../../api.js'


export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'key':{
    tab:'基本信息',
    label:'key',
    type:'text',
    type_params:{
    },
    require: true, //是否必填
  },
}
