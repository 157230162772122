export default{
  'id':{
    label:'id',
    width:'50px',
    value:'id',
    sort_id:'id',
  },
  'name':{
    label:'名称',
    width:10,
    value:'name',
    sort_id:'name',
  },
  'ext':{
    label:'扩展名',
    width:10,
    value:'ext',
    sort_id:'ext',
  },
  'mime_type':{
    label:'MIME类型',
    width:10,
    value:'mime_type',
    sort_id:'mime_type',
  },
  'size':{
    label:'大小',
    width:10,
    value:'size',
    sort_id:'size',
  },
}