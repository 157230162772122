
const toast = function( title,type ){
  return dispatch => {
    dispatch( { type:'TOAST',payload:{ 
      title,
      type
    }} )
  }
}

const clear = function(){
  return dispatch => {
    dispatch( { type:'TOAST_CLEAR' } )
  }
}

export default { toast,clear }