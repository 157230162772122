export default{
  'id':{
    label:'id',
    width:'50px',
    value:'id',
    sort_id:'id',
  },
  'name':{
    label:'名称',
    width:15,
    value:'name',
    sort_id:'name',
  },
  'role.name':{
    label:'角色',
    width:10,
    value:'role.name',
    sort_id:'role.name',
  },
  'mobile':{
    label:'手机',
    width:10,
    value:'mobile',
    sort_id:'mobile',
  },
  'email':{
    label:'邮箱',
    width:10,
    value:'email',
    sort_id:'email',
  },
}