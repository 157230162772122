import API from '../../../../api.js'

export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'product':{
    tab:'产品信息',
    label:'产品清单',
    type:'select',
    type_params:{
      options:[]
    },
    require: false, //是否必填
  },
}
