import { combineReducers } from 'redux'

import default_states from './default_states.js'

import user from './reducers/user.js'
import toast from './reducers/toast.js'
import menu from './reducers/menu.js'
import redirect from './reducers/redirect.js'
import object from './reducers/object.js'
import mask from './reducers/mask.js'
import dialog from './reducers/dialog.js'
import test from './reducers/test.js'

const actions = []

const reducers = combineReducers({
  user,
  toast,
  menu,
  redirect,
  object,
  mask,
  dialog,
  test,
})

export { actions,reducers,default_states }