import axios from 'axios'
import qs from 'qs'
import _ from 'lodash'

let is_dev = ~['localhost', '127.0.0.1'].indexOf(window.location.hostname) ? true : false;

if( window.location.hostname.startsWith('192.168')
  || window.location.hostname.startsWith('10.0') ){
  is_dev = true;
}


const port = is_dev ? ':3028' : ''

const api_base = 'http://' + window.location.hostname + port + '/api'

const axios_instance = axios.create({
  baseURL: api_base,
  timeout: 10000,
  withCredentials: true,
})
//解决post乱码的问题
axios_instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 解决跨域post变成option的问题
axios_instance.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

const API = function (func, data) {
  return axios_instance.post('', {
    func,
    ...data
  })
}


API.get_object_options = function (obj_key, params) {
  return new Promise((resolve, reject) => {
    API('item.list', {
      object: obj_key,
      criteria: [],
      page: 1,
      size: 999,
      order: {},
      is_option: true,
    }).then((result) => {
      if (result.data.status) {
        let options = []
        if(_.has(params, 'empty_label')){
          options.push( {name:params.empty_label,id:0} )
        }
        console.log(options)
        options = [...options,...result.data.data.content]
        if (_.has(params, 'group_by')) {
          options = options.map(i => { return { label: i.name, value: i.id, group: i[params.group_by] } })
        } else {
          options = options.map(i => { return { label: i.name, value: i.id } })
        }
        console.log(options)
        resolve(options)
      } else {
        if (_.has(result, 'data.error')) {
          throw Error(result.data.error.description || 'unknow_error')
        } else {
          throw Error('empty_return_data')
        }
      }
    }).catch(e => {
      reject(e)
    })

  })
}


API.stringify_params = function (params) {
  return _.fromPairs(Object.keys(params).map(k => {
    if (typeof params[k] === 'object') {
      return [k, JSON.stringify(params[k])];
    }
    return [k, params[k]];
  }))
}


API.obj_list = async function (obj_key, _params = {}) {
  const default_params = {
    object: obj_key,
    filters: [],
    page: 1,
    size: 999,
    order: {},
    is_option: true,
  }
  let params = _.defaults(_params, default_params)
  params.object = obj_key;
  params = API.stringify_params(params);
  const results = await API('item.list', params);
  const data = _.get(results, 'data.data', {});
  return data
}

API.obj_add = async function (obj_key, params = {}) {
  const post_data = {
    object: obj_key,
    data: JSON.stringify(params)
  };
  const results = await API('item.add', post_data);
  if (_.has(results, 'data.data')) {
    return results.data.data;
  } else if (_.has(results, 'data.error')) {
    throw Error(results.data.error.description || 'unknow_error')
  } else {
    throw Error('empty_return_data')
  }
  return results.data.data
}

API.obj_edit = async function (obj_key, params = {}) {
  const id = _.get( params, 'id' );
  if( !id ) throw Error('id is required')
  const post_data = {
    object: obj_key,
    id,
    data: JSON.stringify(params)
  };
  const results = await API('item.edit', post_data);
  if (_.has(results, 'data.data')) {
    return results.data.data;
  } else if (_.has(results, 'data.error')) {
    throw Error(results.data.error.description || 'unknow_error')
  } else {
    throw Error('empty_return_data')
  }
  return results.data.data
}


export default API


// import axios from 'axios'
// import qs from 'qs'

// let base_url
// if (window.location.port !== '') {
//   base_url = 'http://' + window.location.hostname + '/hub.ldsfly.com/www_acp/api/api.php?a='
// } else {
//   base_url = '/acp/api/api.php?a='
// }

// export { base_url }

// // setting axios
// const axios_instance = axios.create({
//   baseURL: base_url,
//   timeout: 10000,
//   withCredentials: true,
//   transformRequest: function (data) {
//     return qs.stringify(data)
//   }
// })

// export default axios_instance

// export const values2json = function (data) {
//   return data
// }

// export const process_post_data = function (data, fields) {
//   let ra = []
//   for (let k in data) {
//     if (fields.indexOf(k) >= 0) {
//       if (data[k] instanceof Array || data[k] instanceof Object) {
//         ra[k] = JSON.stringify(data[k])
//       } else {
//         ra[k] = data[k]
//       }
//     }
//   }
//   return ra
// }
