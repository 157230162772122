export default ( state = null,action ) => {

  switch ( action.type ){
    case 'REDIRECT':
      return action.payload
    case 'REDIRECT_RESET':
      return null
    default:
      return state
  }

}