import _ from 'lodash'

const get_childs = function( list,pid = "",depth = 0){
  let fl_list = list.filter( i => ( i['parent_id'] == pid ) )
  
  fl_list = fl_list.map( i => {
    i.depth = depth
    i.childs = get_childs( list,i.id,(depth+1) )
    return i
  } )

  return fl_list
}

export default ( state = { loaded:false,list:[],tree:[],in_ajax:false }, action )=>{
  let new_state = {...state}

  switch ( action.type ){
    case 'MENU_GET':
      new_state.in_ajax = true
      return new_state
    case 'MENU_GET_FAIL':
      new_state.loaded = false
      new_state.in_ajax = false
      return new_state
    case 'MENU_GET_SUCCESS':
      new_state.loaded = true
      new_state.in_ajax = false
      let list = _.values(action.payload)
      new_state.list = list.filter( i => !~['index','logoff','404'].indexOf(i.name) ) //过滤掉无用的菜单项
      //new_state.list = new_state.list.map( i => { i.open = true; return i } )
      new_state.tree = get_childs( new_state.list )
      return new_state
    case 'MENU_TOGGLE':
      new_state.list = new_state.list.map( i => {
        if( i.name == action.payload.name ){
          i.open = !action.payload.is_open
        }else{
          i.open = false
        }
        return i
      } )
      new_state.tree = get_childs( new_state.list )
      return new_state
    default:
      return state
  }
}

  