import API from '../../../../api.js'

export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'category':{
    tab:'基本信息',
    label:'类型',
    type:'select',
    type_params:{
      options:[
        {label:'笔',value:'1'},
        {label:'书签',value:'2'},
        {label:'笔记本',value:'3'},
      ]
    },
    require: false, //是否必填
  },
  'long':{
    tab:'基本信息',
    label:'长',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'width':{
    tab:'基本信息',
    label:'宽',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'height':{
    tab:'基本信息',
    label:'高',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'width':{
    tab:'基本信息',
    label:'重量',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'colors':{
    tab:'基本信息',
    label:'颜色',
    type:'checkboxes',
    type_params:{
      options:[
        {label:'红',value:'1'},
        {label:'黄',value:'2'},
        {label:'蓝',value:'3'},
      ]
    },
    require: false, //是否必填
  },
}
