import React from 'react'
import _ from 'lodash'
import {
  Button,
  Input,
  Select,
  TextArea,
  JsonEditor,
  Checkboxes,
  Radios,
  IdsObjects,
} from './form/'

export default class FormBuilder extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      cur_tab_idx: 0
    }


  }

  change_tab(idx) {
    this.setState({
      cur_tab_idx: idx
    })
  }


  build_field_item(field) {
    return (
      <div className="fr" key={field.key}>
        <dl>
          <dt>{field.label} {field.require ? "*" : ''}</dt>
          <dd>{this.build_field_element(field)}</dd>
        </dl>
      </div>
    )
  }


  build_field_element(field) {
    const type = field.type
    const err = this.props.errors[field.key]
    switch (type) {
      case 'text':
      case 'number':
      case 'email':
        return (<Input 
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          type={type}
          {...field.type_params}
        />)
        break;
      case 'password':
        return (<Input 
          type="password"
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'date':
        return (<Input 
          type="date"
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'datetime':
        return (<Input 
          type="datetime-local"
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'time':
        return (<Input 
          type="time"
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'select':
        return (
          <Select
            name={field.key} 
            setter={this.props.setter}
            getter={this.props.getter}
            error={err}
            {...field.type_params}
          />)
        break;
      case 'textarea':
        return (<TextArea 
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'json':
        return (<JsonEditor 
          name={field.key} 
          setter={this.props.setter}
          getter={this.props.getter}
          className="sm"
          error={err}
          {...field.type_params}
        />)
        break;
      case 'checkboxes':
        return (
          <Checkboxes
            name={field.key} 
            setter={this.props.setter}
            getter={this.props.getter}
            error={err}
            {...field.type_params}
          />)
        break;
      case 'radios':
        return (
          <Radios
            name={field.key} 
            setter={this.props.setter}
            getter={this.props.getter}
            error={err}
            {...field.type_params}
          />)
        break;
      case 'ids_objects':
        return (
          <IdsObjects
            name={field.key} 
            setter={this.props.setter}
            getter={this.props.getter}
            error={err}
            {...field.type_params}
          />)
        break;
      default:
        return (<div className="info">未找到组件</div>)
    }
  }

  render() {
    const mode = _.get(this.props, 'mode', 'add')
    const obj_label = _.get(this.props, 'obj_label', '')
    const mode_label = mode == 'add' ? '添加' : '编辑'
    const submit_label = _.get(this.props, 'submit_label', mode_label)
    const title = obj_label ? <h2>{`${mode_label}${obj_label}`}</h2> : null

    let field_list = [];
    let tab_list = []

    for (let key in this.props.fields) {
      if (_.has(this.props.fields[key], 'tab')) {
        if (tab_list.indexOf(this.props.fields[key].tab) < 0) {
          tab_list.push(this.props.fields[key].tab)
        }
      }
      this.props.fields[key].key = key
      field_list.push(this.props.fields[key])
    }

    const show_submit = _.get(this.props, 'show_submit', true);
    const show_close = _.get(this.props, 'show_close', false);

    let tabs = null;
    let cons = null;
    if (tab_list.length) {
      tabs = (
        <div className="ftabs">
          {tab_list.map( (tab,tab_i) => (
            <button
              key={tab_i}
              className={ (this.state.cur_tab_idx == tab_i) ? 'active' : '' }
              onClick={_=>{ this.change_tab(tab_i) }}
            >{tab}</button>
            )
           )}
        </div>
      )

      cons = (
        <div className="ftcons">
          {
            tab_list.map( ( tab,tab_i )=>{
              return ( <div
                key={tab_i}
                className={'ftcon ' + ( (this.state.cur_tab_idx == tab_i) ? 'active' : '' )
              }>
                {
                  field_list.map( field => {
                    if( field.tab == tab ){
                      return this.build_field_item(field)
                    }
                  })
                }
              </div> )
            })
          }
        </div>
      )
    } else {
      cons = (
        <div className="ftcons">
          <div className="ftcon active">
          {field_list.map( field => this.build_field_item(field))}
          </div>
        </div>
      )
    }


    return (

      <div className={["form_wrap",mode+"_form"].join(" ")}>

        {title}

        {tabs}

        {cons}
        
        { ( show_submit || show_close ) ? (
        <div className="fbtns">
          <div className="fr">
            <dl>
              <dt></dt>
              <dd>
              { show_submit ? (
                <Button className={'btn md ' + ( mode == 'add' ? 'create':'primary' )} 
                onClick={this.props.on_submit} in_ajax={this.props.in_ajax}>{submit_label}</Button>
              ) : null }
              { show_close ? (
                <Button className="btn md normal"
                onClick={this.props.on_close} in_ajax={this.props.in_ajax}>关闭</Button>
              ) : null }
              </dd>
            </dl>
            {this.props.errors.__ ? (
            <dl>
              <dt></dt>
              <dd><p className="error_info">{this.props.errors.__}</p></dd>
            </dl>
            ) : null}
          </div>
        </div>
        ) : null }
      </div>

    );
  }
}
