import React, { Component, PropTypes } from 'react';

class Detail extends Component {
  static propTypes = {
    // className: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="test_detail"></div>
    );
  }
}

export default Detail;
