import React from 'react';
import { connect } from 'react-redux';
import _t from '../../i14n/index.js'

import FAI from '../../fontawesome.js'
import _ from 'lodash'
import formValidation from '../../utils/formValidation'

import ObjectAction from '../../redux/actions/object.js'
import DialogAction from '../../redux/actions/dialog.js'
import Pagi from '../../widgets/Pagi.jsx'
import FormBuilder from '../../widgets/FormBuilder.jsx'
import ListBuilder from '../../widgets/ListBuilder.jsx'
import LoadingDots from '../../widgets/LoadingDots.jsx'
import { Button, Input } from '../../widgets/form'

import object_config from './object_config'

function msp(state) {
  return {
    object: state.object,
    // menus: state.menu
  };
}

function mdp(dispatch) {
  return {
    list: function (object, criteria = [], page = 1, size = 10, order = {}, cb = null) {
      dispatch(ObjectAction.list(object, criteria, page, size, order, cb))
    },
    add: function (object, data, cb = null) {
      dispatch(ObjectAction.add(object, data, cb))
    },
    edit: function (object, id, data, cb = null) {
      dispatch(ObjectAction.edit(object, id, data, cb))
    },
    del: function (object, id, cb = null) {
      dispatch(ObjectAction.del(object, id, cb))
    },
    show_dialog: config => {
      dispatch(DialogAction.show(config))
    }
  }
}

export class Objects extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.cur_path = ''
    this.object = ''
    this.add_fields = {}
    this.edit_fields = {}
    this.list_config = {}

    this.state = {
      form_mode: '', // add/edit
      obj: '',
      obj_label: '',
      add_obj: {},
      add_errors: {},
      edit_obj: {},
      edit_errors: {},
    };
  }

  componentDidMount() {
    // console.log('componentDidMount')
    const prop_object = _.get(this.props,'obj','');
    if ( this.object != prop_object ){
      this.object = prop_object;
      this.setState({
        obj: prop_object,
        obj_label: _.get(this.props,'obj_label',''),
      })
      this.add_fields = object_config[this.object] ? object_config[this.object].add : {}
      this.edit_fields = object_config[this.object] ? object_config[this.object].edit : {}
      this.list_config = object_config[this.object] ? object_config[this.object].list : {}
      this.init_list()
    }
  }

  componentDidUpdate(prevProps,prevState) {
    const prop_object = _.get(this.props,'obj','');
    if ( this.object != prop_object ){
      this.object = prop_object;
      this.setState({
        obj: prop_object,
        obj_label: _.get(this.props,'obj_label',''),
      })
      this.add_fields = object_config[this.object] ? object_config[this.object].add : {}
      this.edit_fields = object_config[this.object] ? object_config[this.object].edit : {}
      this.list_config = object_config[this.object] ? object_config[this.object].list : {}
      this.init_list()
    }
  }

  update_list(params, reset = false) {
    const fl_params = _.pick(params, ['criteria', 'page', 'size', 'order'])
    const fl_object = _.pick(this.props.object, ['criteria', 'page', 'size', 'order'])
    const default_object = { criteria: [], page: 1, size: 10, order: { id: 'desc' } }

    let new_params = {}
    if (reset) {
      new_params = { ...default_object, ...fl_params }
    } else {
      new_params = { ...default_object, ...fl_object, ...fl_params }
    }

    this.props.list(this.object, new_params.criteria, new_params.page, new_params.size, new_params.order)
  }

  init_list() {
    this.update_list({ object: this.object }, true)
  }

  order_change(key, order) {
    let order_obj = {}
    order_obj[key] = order
    this.update_list({ order: order_obj })
  }

  on_pagi_change(page) {
    this.update_list({ page })
  }

  // check_requires(form_obj, fields, error_key, full_mode = false) {
  //   let err_obj = {}
  //   let has_err = false

  //   if (full_mode) {
  //     for (let k in fields) {
  //       if( _.has(fields,k) ){
  //         const is_require = !!fields[k].require
  //         if (is_require) {
  //           if (!form_obj[k]) {
  //             has_err = true
  //             err_obj[k] = 'required'
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     for (let k in form_obj) {
  //       if (k == 'id' || k == '_id' || k == 'object') continue
  //       if( _.has(fields,k) ){
  //         const is_require = _.get(fields[k],'require',false)
  //         if (is_require) {
  //           if (!form_obj[k]) {
  //             has_err = true
  //             err_obj[k] = 'required'
  //           }
  //         }
  //       }
  //     }
  //   }

  //   this.setState( ( prevState, props ) => {
  //     console.log( `new_errors of ${error_key}` )
  //     let new_errors = {...prevState[error_key]}
  //     console.log( JSON.stringify(new_errors) )
  //     Object.keys(new_errors).map( key => {
  //       if( new_errors[key] === 'required' && !err_obj[key] ){
  //         delete new_errors[key]
  //       }
  //     } )

  //     if (has_err) {
  //       Object.keys( err_obj ).map( key => {
  //         if( !new_errors[key] ){
  //           new_errors[key] = 'required'
  //         }
  //       } )
  //     }
  //     console.log( JSON.stringify(new_errors) )
  //     return {[error_key]:new_errors}
  //   } )

  //   return !has_err
  // }

  on_delete(item) {

    this.props.show_dialog({
      type: 'dialog',
      title: '删除',
      content: `您确认删除${this.state.obj_label} "${item.name}" 吗？`,
      on_confirm: close => {
        this.props.del(this.object, item.id)
        window.setTimeout(() => {
          this.update_list()
        }, 100);
        close();
      },
      on_cancel: true,
    })

  }

  on_edit(item) {
    this.set_form_mode('edit');

    const clone_item = _.cloneDeep(item)
    _.each( this.edit_fields, (v,k) => {
      // if( k.endsWith('_id')  ){
      //   const key = k.slice(0,-3)
      //   clone_item[k] = _.get( clone_item,`${key}.id`,'' )
      // }else if( k.endsWith('_ids')  ){
      //   const key = k.slice(0,-4)
      //   clone_item[k] = _.get( clone_item,`${key}s`,[] ).map( i=>i.id )
      // }
    } )

    this.setState({
      edit_obj: clone_item,
      edit_errors: {}
    })
  }

  add_item() {
    formValidation( this.state.add_obj,this.add_fields,true ).then( errors => {
      if( _.isEmpty(errors) ){
        this.props.add(this.object, this.state.add_obj, result_obj => {
          if( result_obj.type == 'OBJ_ADD_SUCCESS' ){
            this.update_list()
            this.close_form_popup()
            this.clear_from_obj('add')
          }
        })
      }else{
        this.setState({add_errors: errors})
      }
    } ).catch( e => {} );
  }

  edit_item() {
    formValidation( this.state.edit_obj,this.edit_fields,true ).then( errors => {
      if( _.isEmpty(errors) ){
        this.props.edit(this.object, this.state.edit_obj.id, this.state.edit_obj, result_obj => {
          if( result_obj.type == 'OBJ_EDIT_SUCCESS' ){
            this.update_list()
            this.close_form_popup()
            this.clear_from_obj('edit')
          }
        })
      }else{
        this.setState({edit_errors: errors})
      }
    } ).catch( e => {} );
  }

  //add getter
  _ag(key) {
    // if( key.endsWith('_id') && !_.has( this.state.add_obj,key ) ){
    //   key = key.slice( 0,-3 ) + '.id'
    // }else if( key.endsWith('_ids') && !_.has( this.state.add_obj,key )  ){
    //   key = key.slice( 0,-4 ) + 's'
    //   return _.get( this.state.add_obj,key,[] ).map( i => i.id )
    // }
    // return _.has( this.state.add_obj,key ) ? _.get( this.state.add_obj,key ) : ''
    return _.get( this.state.add_obj,key );
  }

  //add setter
  _as(key, value, _cb = _ => {}) {
    this.setState( (prevState,props) =>{
      let new_obj = { ...prevState.add_obj }
      new_obj[key] = value
      formValidation( new_obj,this.add_fields ).then( errors => {
        this.setState({add_errors: errors}, _ => {
          _cb(null);
        })
      } ).catch( e => {
        _cb(e);
      } );
      return {
        'add_obj': new_obj
      }
    })
  }

  //edit getter
  _eg(key) {
    // if( key.endsWith('_id') && !_.has( this.state.edit_obj,key ) ){
    //   key = key.slice( 0,-3 ) + '.id'
    // }else if( key.endsWith('_ids') && !_.has( this.state.edit_obj,key ) ){
    //   key = key.slice( 0,-4 ) + 's'
    //   return _.get( this.state.edit_obj,key,[] ).map( i => i.id )
    // }
    return _.get( this.state.edit_obj,key )
  }

  //edit setter
  _es(key, value, _cb = _ => {}) {
    this.setState( (prevState,props) =>{
      let new_obj = { ...prevState.edit_obj }
      new_obj[key] = value
      formValidation( new_obj,this.edit_fields ).then( errors => {
        this.setState({edit_errors: errors}, _ => {
          _cb(null);
        })
      } ).catch( e => {
        _cb(e);
      } );
      return {
        'edit_obj': new_obj
      }
    })
  }

  render_pagi() {
    return (
      <Pagi
          page={this.props.object.page}
          size={this.props.object.size}
          total={this.props.object.total_page}
          count={this.props.object.total_count}
          onChange={this.on_pagi_change.bind(this)}
        />
    )
  }

  set_form_mode(form_mode) {
    form_mode = form_mode || ''
    this.setState({
      form_mode: form_mode
    })
  }

  close_form_popup() {
    this.set_form_mode()
  }

  clear_from_obj(mode) {
    switch (mode) {
      case 'add':
        this.setState({
          add_obj: {},
          add_errors: {},
        })
        break;
      case 'edit':
        this.setState({
          edit_obj: {},
          edit_errors: {},
        })
        break;
    }
  }

  // set_error( mode ){
  //   const errors_key = `${mode}_errors`;
  //   return ( key, error ) => {
  //     console.log( `//START set_error ${errors_key} ${key} ${error}` ) 
  //     this.setState( (prevState, props) => {
  //       const new_errors = {...prevState[errors_key]};
  //       console.log(`new_errors`)
  //       console.log(JSON.stringify(new_errors))
  //       if(error === null){
  //         if( new_errors[key] ){
  //           delete new_errors[key];
  //           return {[errors_key]:new_errors}
  //         }
  //       }else{
  //         console.log( `new_errors[key] !== error ${new_errors[key] !== error}`)
  //         if( new_errors[key] !== error ){
  //           new_errors[key] = error;
  //           console.log( {
  //             [errors_key]:new_errors
  //           } )
  //           return {[errors_key]:new_errors};
  //         }
  //       }
  //       return prevState;
  //     } )
  //     console.log(`//END set_error`)
  //   }
  // }

  render() {

    // console.log( '==== Objects render ====' )

    // console.log( this.props.object )
    // console.log( this.props )


    const has_data = !!this.props.object.list.length

    const List = has_data ? (<ListBuilder 
      config={this.list_config}
      list={this.props.object.list}
      order={this.props.object.order}
      on_order_change={this.order_change.bind(this)}
      on_delete={this.on_delete.bind(this)}
      on_edit={this.on_edit.bind(this)}
    />) : null
    const Pagi = has_data ? this.render_pagi() : null

    let form_content;

    switch (this.state.form_mode) {
      case 'add':
        form_content = (<FormBuilder 
          _this={this}
          obj_label={this.state.obj_label}
          fields={this.add_fields}
          errors={this.state.add_errors}
          mode={this.state.form_mode}
          setter={this._as.bind(this)}
          getter={this._ag.bind(this)}
          on_close={this.close_form_popup.bind(this)}
          on_submit={this.add_item.bind(this)}
          />)
        break;
      case 'edit':
        form_content = (<FormBuilder 
          _this={this}
          obj_label={this.state.obj_label}
          fields={this.edit_fields}
          errors={this.state.edit_errors}
          mode={this.state.form_mode}
          setter={this._es.bind(this)}
          getter={this._eg.bind(this)}
          on_close={this.close_form_popup.bind(this)}
          on_submit={this.edit_item.bind(this)}
          />)
        break;
    }

    return (
      <React.Fragment>
        <section className="title">
          <h2>{this.state.obj_label}</h2>
          <div className="ops">
            {
              this.add_fields ? (
                <Button className="btn create md" onClick={ () => { this.set_form_mode('add') }} icon="plus">添加</Button>
              ) : null
            }
          </div>
        </section>
        <section className="list_wrap">
          <section className="list_ops">
            <div className="left">
              批处理:
            </div>
            <div className="right">
              <section className="list_filters">
                <Button className="btn normal md" icon="filter">筛选</Button>
                <div className="filter_popup">
                  <dl>
                    <dt></dt>
                    <dd></dd>
                  </dl>
                </div> 
              </section>
            </div>
          </section>
          
          { this.props.object.in_ajax ? (
            <section className="no_data"><LoadingDots /></section>
            ) : (
            has_data ? 
            (
              <React.Fragment>
                <section className="list">{List}</section>
                <section className="pagi">{Pagi}</section>
              </React.Fragment>
            ) : (
              <section className="no_data"><FAI icon="exclamation-circle"/><span>未查询到符合条件的数据</span></section>
            )
          ) }
        </section>
        { form_content ? (
          <React.Fragment>
            <section className="mask" onClick={this.close_form_popup.bind(this)}></section>
            <section className="form_popup_wrap">
              <div className="form_popup">
                <div className="close" onClick={this.close_form_popup.bind(this)}><FAI icon="times" /></div>
                {form_content}
              </div>
            </section>
          </React.Fragment>
        ) : null }        
      </React.Fragment>
    );
  }
}

export default connect(
  msp, mdp
)(Objects)
