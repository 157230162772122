import _ from 'lodash'
import v_json from './json.js'
import v_required from './required.js'

const __validator_map = {
  json: v_json,
  required: v_required,
}

/**
 * 根据field配置项获取应该应用的检查方法
 *
 * @param      {<object>}  field_config  The field configuration
 * @return     {<array of validator>}  All validations.
 */
function get_all_validators( field_config ){
  let validations = [];
  // 根据type添加默认验证
  switch( field_config.type ){
    case 'json':
      validations.push('json');
      break;
  }
  // 添加自定义验证
  if( _.isArray(field_config.validations) ){
    validations = _.concat(validations,field_config.validations);
  }

  // 必填
  if( field_config.require ){
    validations.push('required');
  }

  validations = _.uniq(validations);
  let validators = validations.map( key => __validator_map[key] );
  return _.compact(validators);
}


export default ( form_obj,fields,full_mode=false ) => (new Promise((rsl, rej) => {
  Promise.all( 
    Object.keys( fields ).map( key => {
      if( !_.has( form_obj, key ) && !full_mode ) return null;
      const validators = get_all_validators(fields[key]);
      if( !validators.length ) return null;

      console.log('validators')
      console.log(validators)

      return new Promise( async(rsl,rej) => {
        try{
          let value;
          for ( const validator of validators ){
            value = await validator( form_obj,key )
          }
          rsl({key,value})          
        }catch(e){
          console.log('error')
          console.log(e)
          rsl({key,...e})
        }
      } )
    } )
  ).then( result => {
    result  = result.filter( i => i && i.err_code )
                    .map( i => [ i.key,i.err_code ] );
    rsl(_.fromPairs(result));
  } ).catch( e => {
    rej(e)
  } )
}));
