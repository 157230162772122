const redirect = function( path ){
  return dispatch => {
    dispatch( { type:'REDIRECT',payload:path } )
  }
}

const redirect_reset = function( path ){
  return dispatch => {
    dispatch( { type:'REDIRECT_RESET' } )
  }
}

export default { redirect,redirect_reset }