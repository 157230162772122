import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import Header from '../../widgets/Header.jsx'
import Footer from '../../widgets/Footer.jsx'
import SideMenu from '../../widgets/SideMenu.jsx'
import LoadingDots from '../../widgets/LoadingDots.jsx'

import FAI from '../../fontawesome.js'

import Objects from './Objects.jsx'
import Dashboard from './Dashboard.jsx'
import UserProfile from './UserProfile.jsx'


const component_map = {
  'Objects' : Objects,
  'Dashboard' : Dashboard,
  'UserProfile' : UserProfile,
}



function msp(state) {
  return {
    menu: state.menu
  };
}

function mdp(dispatch) {
  return {
    // list: function (object, criteria = [], page = 1, size = 10, order = {}) {
    //   dispatch(ObjectAction.list(object, criteria, page, size, order))
    // },
  }
}


class Index extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      is_side_menu_hide: true
    }

  }

  toggle_menu() {
    this.setState({
      is_side_menu_hide: !this.state.is_side_menu_hide
    })
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      if (!this.state.is_side_menu_hide) {
        this.toggle_menu()
      }
    }
  }

  renderBackend(route) {
    const menus = _.get(this.props, 'menu.list', [])

    if( !menus.length ){
      return <LoadingDots />
    }

    const matched_menu = menus.filter( i=>{
      return route.location.pathname === `/backend${i.path}`
    } )[0] || null
    
    if( matched_menu ){
      const component_name = _.get( matched_menu,'component','' )
      const Comp = _.get( component_map,component_name, null )
      const props = _.get( matched_menu,'props',{} )

      if( Comp ){
        return <Comp route={route} menu={matched_menu} {...props} />
      }else{
        return <div className="info_box error">未知组件 {component_name}</div>
      }
    }else{
      return <Redirect to="/backend/dashboard" />
    }

  }

  render() {
    return (
      <div className="layout layout_backend">
        <Header />
        <div className="page page_backend">
          <div className={'page_side' + (this.state.is_side_menu_hide ? ' hide' : '')}>
            <SideMenu location={this.props.location}></SideMenu>
          </div>
          <div className="page_main">
            <Switch>
              <Route path="/backend/" render={this.renderBackend.bind(this)} />
              {/*<Route path="/backend/dashboard" component={Dashboard} />*/}
              {/*<Route path="/backend/user_profile" component={UserProfile} />*/}
              {/*<Route path="/backend/:cat/:obj" component={Objects} />*/}
              {/*<Route path="/backend/" render={ () => {return <Redirect to="/backend/dashboard" />} } />*/}
            </Switch>
          </div>
          <div className={'menu_toggle_mask' + (this.state.is_side_menu_hide ? ' hide' : '')}
            onClick={this.toggle_menu.bind(this)}></div>
          <div className={'menu_toggle' + (this.state.is_side_menu_hide ? ' hide' : '')}
            onClick={this.toggle_menu.bind(this)} >
            <i className="hide"><FAI icon="bars" /></i>
            <i className="show"><FAI icon="chevron-left" /></i>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


export default connect(
  msp, mdp
)(Index)
