export default{
  'id':{
    label:'id',
    width:'50px',
    value:'id',
    sort_id:'id',
  },
  'name':{
    label:'名称',
    width:10,
    value:'name',
    sort_id:'name',
  },
}
