import API from '../../api.js'
import ToastAction from './toast.js'

const list = function( object, criteria = [], page = 1, size = 10, order = {}, cb = null ){
  return dispatch => {
    dispatch( { type:'OBJ_LIST',payload:{ object,criteria,page,size,order }} )
    dispatch( ToastAction.toast( '正在查询...','info' ) )
    API( 'item.list',{
      object,
      criteria:JSON.stringify(criteria),
      page,
      size,
      order:JSON.stringify(order),
    } ).then( ( result ) => {
      if( result.data.status ){
        const result_obj = { type:'OBJ_LIST_SUCCESS',payload:{...result.data.data,size} }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '查询成功！','success' ) )
      }else{
        const result_obj = { type:'OBJ_LIST_FAIL',payload:result.data.error }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      }
    } ).catch( e => {
        const result_obj = { type:'OBJ_LIST_FAIL',payload:e }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )
  }
}


const add = function( object, data = {}, cb = null ){
  return dispatch => {
    dispatch( { type:'OBJ_ADD',payload:{ object,data }} )
    dispatch( ToastAction.toast( '正在添加...','info' ) )
    API( 'item.add',{
      object,
      data:JSON.stringify(data),
    } ).then( ( result ) => {
      if( result.data.status ){
        const result_obj = { type:'OBJ_ADD_SUCCESS',payload:{...result.data.data} }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '添加成功！','success' ) )
      }else{
        const result_obj = { type:'OBJ_ADD_FAIL',payload:result.data.error }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      } 
    } ).catch( e => {
        const result_obj = { type:'OBJ_ADD_FAIL',payload:e }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )
  }
}

const edit = function( object, id, data = {}, cb = null ){
  return dispatch => {
    dispatch( { type:'OBJ_EDIT',payload:{ object,id,data }} )
    dispatch( ToastAction.toast( '正在更新...','info' ) )
    API( 'item.edit',{
      object,
      id,
      data:JSON.stringify(data),
    } ).then( ( result ) => {
      if( result.data.status ){
        const result_obj = { type:'OBJ_EDIT_SUCCESS',payload:{...result.data.data} }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '更新成功！','success' ) )
      }else{
        const result_obj = { type:'OBJ_EDIT_FAIL',payload:result.data.error }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      } 
    } ).catch( e => {
        const result_obj = { type:'OBJ_EDIT_FAIL',payload:e }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )
  }
}

const del = function( object, id, cb = null ){
  return dispatch => {
    dispatch( { type:'OBJ_DELETE',payload:{ object,id }} )
    dispatch( ToastAction.toast( '正在删除...','info' ) )
    API( 'item.del',{
      object,
      id,
    } ).then( ( result ) => {
      if( result.data.status ){
        const result_obj = { type:'OBJ_DELETE_SUCCESS',payload:{...result.data.data} }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '删除成功！','success' ) )
      }else{
        const result_obj = { type:'OBJ_DELETE_FAIL',payload:result.data.error }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      } 
    } ).catch( e => {
        const result_obj = { type:'OBJ_DELETE_FAIL',payload:e }
        dispatch( result_obj )
        if( typeof cb === 'function' ){ cb(result_obj) }
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )
  }
}





export default { list,add,edit,del }