import React from 'react';
import _ from 'lodash';
import ErrorInfo from './ErrorInfo.jsx';
import LoadingDots from '../LoadingDots.jsx';

export default class Checkboxes extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    let options = [];
    if (_.isArray(props.options)) {
      options = props.options
    }

    this.state = {
      options,
      value: props.getter(props.name) || [],
      in_ajax: false,
      error: '',
    }

  }

  componentDidMount() {
    this.get_remote_options();
  }

  componentDidUpdate(prevProps, prevState) {
    const value = this.props.getter(this.props.name) || []
    if (!_.isEqual(value, this.state.value)) {
      this.setState({
        value
      })
    }

  }

  get_remote_options() {

    if (typeof this.props.options === 'function' && !this.state.in_ajax) {
      this.setState({
        in_ajax: true,
        error: '',
      })
      this.props.options().then(data => {
        this.setState({
          options: data,
          in_ajax: false,
        })
      }).catch(e => {
        this.setState({
          error: e ? e.message : 'Unknow Error',
          in_ajax: false,
        })
      })
    }

  }




  onchange(evt, item) {
    let value = [...this.state.value]

    const index = value.indexOf(item.value)
    if (~index) {
      value.splice(index, 1)
    } else {
      value.push(item.value)
    }

    this.props.setter(this.props.name, value)
  }

  render() {

    const _props = { ...this.props }
    delete _props.options

    const has_err = !!_props.error
    let error = ''
    if (has_err) {
      error = _props.error
      delete _props.error
    }

    let className = (_props.className || '') + ' fe_checkboxes' + (has_err ? ' has_error' : '')
    delete _props.className

    let StatusComponenet = null;
    let grouped = false;
    let options = [];

    if (this.state.in_ajax) {
      StatusComponenet = <span className="in-ajax"><LoadingDots/> 数据获取中</span>
    } else {
      if (!this.state.error) {
        if (_.has(this, 'state.options') && _.isArray(this.state.options)) {
          if (this.state.options.length) {
            if (_.has(this.state.options[0], 'group')) {
              grouped = true;
              let grouped_options = {};
              this.state.options.map(i => {
                if (!_.has(grouped_options, i.group)) {
                  grouped_options[i.group] = {
                    group: i.group,
                    options: [],
                  }
                }
                grouped_options[i.group].options.push(i)
              })
              options = _.values(grouped_options)
            } else {
              options = this.state.options
            }
          } else {
            StatusComponenet = (<span className="no-options">还没有选项，请先添加选项。</span>);
          }
        } else {
          StatusComponenet = (<span className="no-options">选项不存在，请联系管理员。</span>);
        }
      }
    }

    return (
      <div className={className}>
        { !this.state.in_ajax && options && !this.state.error && (
         grouped ?
          options.map( g => (
            <div className="group" key={g.group}>
              { g.group ? <h4>{g.group}</h4> : null }              
              { g.options.map( i => 
                <label key={i.value}>
                  <input type="checkbox" value={i.value} 
                    onChange={e=>{ this.onchange(e,i) }}
                    checked={~this.state.value.indexOf(i.value)} />
                  <span>{i.label}</span>
                </label> ) }
            </div>
          ) ) :
          options.map( i => (
            <label key={i.value}>
              <input type="checkbox" value={i.value} 
                onChange={e=>{ this.onchange(e,i) }}
                checked={~this.state.value.indexOf(i.value)} />
              <span>{i.label}</span>
            </label>
            )
          )
        ) }

        { ( StatusComponenet || this.state.error ) && (
          <div className="info">
            {StatusComponenet}
            { this.state.error && (
              <span className="error">出现错误，请<span className="a_like"
                onClick={this.get_remote_options.bind(this)}>重试</span>
                &nbsp;&nbsp;
                ({this.state.error})</span>
            ) }
          </div>
        ) }


        { has_err ? <ErrorInfo error={error}/> : null }
      
      </div>
    );
  }
}
