export default (state = [], { type, payload }) => {
  switch (type) {
    case 'MASK_PUSH':
      if (payload) {
        const new_state = [...state];
        new_state.push(payload);
        return new_state;
      }
      return state
    case 'MASK_POP':
      if (payload) {
        const new_state = [...state];
        const payload_idx = new_state.indexOf(payload)
        if (~payload_idx) {
          new_state.splice(payload_idx, 1);
          return new_state;
        }
      }
      return state
    default:
      return state
  }
}
