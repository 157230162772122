import API from '../../api.js'
import ToastAction from './toast.js'
import MenuAction from './menu.js' 

const auto_login = function( from_path ){
  return dispatch => {
    dispatch( { type:'USER_AUTO_LOGIN'} )
    dispatch( ToastAction.toast( '正在尝试自动登录...','info' ) )
    API( 'acl.is_login' ).then( ( result ) => {
      if( result.data.status ){
        dispatch( { type:'USER_AUTO_LOGIN_SUCCESS',payload:{...result.data.data} } )
        dispatch( { type:'REDIRECT',payload:( ( from_path == '/' || from_path == '' ) ? '/backend' : from_path ) } )
        dispatch( ToastAction.toast( '登录成功！欢迎回来 '+result.data.data.name,'success' ) )
        dispatch( MenuAction.get_menu( true ) )
      }else{
        dispatch( { type:'USER_AUTO_LOGIN_FAIL',payload:result.data.error } )
        dispatch( ToastAction.toast( '自动登录失败，请手动登录。','stop' ) )
      }
    } ).catch( e => {
        dispatch( { type:'USER_AUTO_LOGIN_FAIL',payload:e } )
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络并手动登录。','error' ) )
    } )
  }
}

const login = function( username,password,from_path ){
  return dispatch => {
    dispatch( { type:'USER_LOGIN',payload:{ username,password }} )
    dispatch( ToastAction.toast( '正在登录...','info' ) )
    API( 'user.login',{
      name:username,
      password:password
    } ).then( ( result ) => {
      if( result.data.status ){
        dispatch( { type:'USER_LOGIN_SUCCESS',payload:{...result.data.data} } )
        dispatch( { type:'REDIRECT',payload:( ( from_path == '/' || from_path == '' ) ? '/backend' : from_path ) } )
        dispatch( ToastAction.toast( '登录成功！欢迎回来 '+result.data.data.name,'success' ) )
        dispatch( MenuAction.get_menu( true ) )
      }else{
        dispatch( { type:'USER_LOGIN_FAIL',payload:result.data.error } )
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      }
    } ).catch( e => {
        dispatch( { type:'USER_LOGIN_FAIL',payload:e } )
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )
  }
}


const logout = function(){
  return dispatch => {
    dispatch( { type:'USER_LOGOUT' } )
    dispatch( ToastAction.toast( '正在退出登录...','info' ) )
    
    API( 'user.logout' ).then( ( result ) => {
      if( result.data.status ){
        dispatch( { type:'USER_LOGOUT_SUCCESS',payload:{...result.data.data} } )
        dispatch( ToastAction.toast( '退出成功。再会！','success' ) )
      }else{
        dispatch( { type:'USER_LOGOUT_FAIL',payload:result.data.error } )
        dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
      }
    } ).catch( e => {
        dispatch( { type:'USER_LOGOUT_FAIL',payload:e } )
        dispatch( ToastAction.toast( '网络或服务器错误，请检查网络。','error' ) )
    } )



  }
}


export default { auto_login, login, logout }