import API from '../../../../api.js'


export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'key':{
    tab:'基本信息',
    label:'key',
    type:'text',
    type_params:{
    },
    require: true, //是否必填
  },
  'parent_id':{
    tab: '基本信息',
    label: '父菜单',
    type:'select',
    type_params:{
      options:function( _this ){
        return API.get_object_options('wc_menu')
      }
    }
  },
  'path':{
    tab:'基本信息',
    label:'路径',
    type:'text',
    type_params:{
    },
    require: true, //是否必填
  },
  'icon':{
    tab:'基本信息',
    label:'图标',
    type:'text',
    type_params:{
    },
    // require: true, //是否必填
  },
  'component':{
    tab:'基本信息',
    label:'组件',
    type:'text',
    type_params:{
    },
    // require: true, //是否必填
  },
  'redirect':{
    tab:'基本信息',
    label:'跳转',
    type:'text',
    type_params:{
    },
    // require: true, //是否必填
  },
  'props':{
    tab:'基本信息',
    label:'props',
    type:'json',
    type_params:{
      // icon:'cog',
      rows:'10'
    },
    // require: true, //是否必填
  },
  'order':{
    tab:'基本信息',
    label:'排序',
    type:'number',
    type_params:{
    },
    // require: true, //是否必填
  },
  'prems':{
    tab:'基本信息',
    label:'权限',
    type:'checkboxes',
    type_params:{
      options:{
        wc:'wc',
        admin:'admin',
      }
    },
    // require: true, //是否必填
  },
}
