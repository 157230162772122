import React from 'react';

export default class Dashboard extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes.string,
  // };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <section className="title">
          <h2>控制台</h2>
          <div className="ops"></div>
        </section>
        <section className="dashboard_wrap">

        </section>
      </React.Fragment>

    );
  }
}
