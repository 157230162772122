export default ( state = {
  idx:0,
},action ) => {

  switch ( action.type ){
    case 'TEST_SET_IDX':
      return { idx:action.payload.idx }
    default:
      return state
  }

}