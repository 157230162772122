import React from 'react';
import LoginPanel from '../../widgets/LoginPanel.jsx'
import Footer from '../../widgets/Footer.jsx'
import FAI from '../../fontawesome.js'

export default class index extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="layout layout_login">
        <div className="page page_login">
          <div className="logo">
            <div className="img"><img src="/images/logo.png"/></div>
            <hgroup>
              <h1>礼品定制管理系统</h1>
              <h2>Custom Gift Manage System</h2>
            </hgroup>
          </div>
          <LoginPanel {...this.props} ></LoginPanel>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
