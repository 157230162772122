import React from 'react';
import FAI from '../../fontawesome.js'
import ErrorInfo from './ErrorInfo.jsx'

const Input = (props) => {
  
  const _props = {...props}

  const has_err = !!_props.error
  let error = ''
  if( has_err ){
    error = _props.error
    delete _props.error
  }


  let className = (_props.className || '') + ' fe_input' + ( has_err ? ' has_error' : '' )
  delete _props.className


  let Icon = null
  if( _props.icon ){
    Icon = <i><FAI icon={_props.icon} /></i>
    className = className + ' has_icon'
    delete _props.icon
  }


  if( _props.name ){
    const name = _props.name

    let getter;
    if( _props.getter ){
      getter = _props.getter
      _props.value = getter( name ) || ''
      delete _props.getter
    }

    let setter;
    if( _props.setter ){
      setter = _props.setter
      _props.onChange = function( e ){
        setter( name, e.target.value)
      }
      delete _props.setter
    }

    if( getter || setter ){
      delete _props.name
    }

  }

  return (
    <label className={className}>
      <input {..._props} />
      {Icon}
      { has_err ? <ErrorInfo error={error}/> : null }
    </label>
  )
}

export default Input;