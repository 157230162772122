import { v4 as uuid_v4 } from 'uuid'

export default ( state = [], action )=>{
  let new_state = []
  switch ( action.type ){
    case 'TOAST':
      new_state = []
      if( action.payload.title ){
        new_state = [{
          title:action.payload.title,
          type:action.payload.type || 'info',
          time:Date.now(),
          id:uuid_v4(),
        }]
      }
      return new_state
    case 'TOAST_APPEND':
      new_state = []
      const min_time = Date.now() - 10000 // 10s
      state.forEach( item => {
        if( item.time > min_time  ){
          new_state.push( item )
        }
      } )
      if( action.payload.title ){
        new_state.push(
          {
            title:action.payload.title,
            type:action.payload.type || 'info',
            time:Date.now(),
            id:uuid_v4(),
          }
        )
      }
      return new_state
    case 'TOAST_CLEAR':
      return []
    default:
      return state
  }
}

