import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import FormBuilder from '../../widgets/FormBuilder.jsx'


function msp(state) {
  return {
    user: state.user
  };
}

function mdp(dispatch) {
  return {
    // list: function (object, criteria = [], page = 1, size = 10, order = {}) {
    //   dispatch(ObjectAction.list(object, criteria, page, size, order))
    // },
    // add: function (object, data) {
    //   dispatch(ObjectAction.add(object, data))
    // },
    // edit: function (object, id, data) {
    //   dispatch(ObjectAction.edit(object, id, data))
    // },
    // del: function (object, id) {
    //   dispatch(ObjectAction.del(object, id))
    // }
  }
}


class UserProfile extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes.string,
  // };

  constructor(props) {
    super(props);

    this.fields = {
      'mobile':{
        tab:'基本信息',
        label:'手机',
        type:'text',
        type_params:{},
        require: false, //是否必填
      },
      'email':{
        tab:'基本信息',
        label:'邮箱',
        type:'text',
        type_params:{},
        require: false, //是否必填
      },
      'wechat':{
        tab:'基本信息',
        label:'微信',
        type:'text',
        type_params:{},
        require: false, //是否必填
      },
      'qq':{
        tab:'基本信息',
        label:'QQ',
        type:'text',
        type_params:{},
        require: false, //是否必填
      },
      'old_password':{
        tab:'修改密码',
        label:'当前密码',
        type:'password',
        type_params:{},
        require: false, //是否必填
      },
      'new_password':{
        tab:'修改密码',
        label:'新密码',
        type:'password',
        type_params:{},
        require: false, //是否必填
      },
      'new_password_2':{
        tab:'修改密码',
        label:'再输入新密码',
        type:'password',
        type_params:{},
        require: false, //是否必填
      },
    }

    this.state = {
      edit_errors: {},
      edit_obj:{}
    };

  }


  //edit getter
  _eg(key) {
    if( key.endsWith('_id') && !_.has( this.state.edit_obj,key ) ){
      key = key.slice( 0,-3 ) + '.id'
    }else if( key.endsWith('_ids') && !_.has( this.state.edit_obj,key ) ){
      key = key.slice( 0,-4 ) + 's'
      return _.get( this.state.edit_obj,key,[] ).map( i => i.id )
    }
    return _.has( this.state.edit_obj,key ) ? _.get( this.state.edit_obj,key ) : ''
  }

  //edit setter
  _es(key, value) {
    let new_obj = { ...this.state.edit_obj }
    new_obj[key] = value
    this.setState({
      'edit_obj': new_obj
    })
    this.check_requires(new_obj, this.edit_fields, 'edit_errors')
  }


  on_edit(item){

    this.set_form_mode('edit');

    const clone_item = _.cloneDeep(item)
    _.each( this.edit_fields, (v,k) => {
      if( k.endsWith('_id')  ){
        const key = k.slice(0,-3)
        clone_item[k] = _.get( clone_item,`${key}.id`,'' )
      }else if( k.endsWith('_ids')  ){
        const key = k.slice(0,-4)
        clone_item[k] = _.get( clone_item,`${key}s`,[] ).map( i=>i.id )
      }
    } )

    this.setState({
      edit_obj: clone_item
    })

  }

  edit_item() {
    if (this.check_requires(this.state.edit_obj, this.edit_fields, 'edit_errors', true)) {
      this.props.edit(this.object, this.state.edit_obj.id, this.state.edit_obj)
      window.setTimeout(() => {
        this.update_list()
        this.close_form_popup()
        this.clear_from_obj('edit')
      }, 200);
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="title">
          <h2>用户资料</h2>
          <div className="ops">
          </div>
        </section>
        <section className="form_wrap">
          <FormBuilder 
            _this={this}
            obj_label=""
            submit_label="更新资料"
            fields={this.fields}
            errors={this.state.edit_errors}
            mode="edit"
            setter={this._es.bind(this)}
            getter={this._eg.bind(this)}
            on_submit={this.edit_item.bind(this)}
            />
        </section>
      </React.Fragment>
    );
  }
}



export default connect(
  msp, mdp
)(UserProfile)
