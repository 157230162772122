import React from 'react';
import FAI from '../fontawesome.js'

const LoadingDots = (props) => {
  return (
    <span className="loading_dots">
      <i><FAI icon="circle" /></i>
      <i><FAI icon="circle" /></i>
      <i><FAI icon="circle" /></i>
      <i><FAI icon="circle" /></i>
    </span>
  )
}

export default LoadingDots;