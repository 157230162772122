import React, { Component } from 'react';
import FAI from '../fontawesome.js'

export default class HeaderDropDown extends Component {
  render() {

    const Icon = <FAI icon={this.props.icon} />
    const Title = typeof this.props['title'] == 'undefined' ? null : (<span>{this.props.title}</span>)

    return (
      <div className="header_dropdown">
        <h4>{Icon}{Title}</h4>
        <div className="panel">
          {this.props.children}
        </div>
      </div>
    );
  }
}
