import React from 'react';
import { connect } from 'react-redux';
import _ from 'loadsh';

function msp(state) {
  return {
    mask: state.mask
  };
}

export class Mask extends React.Component {

  constructor(props) {
    super(props);
    this.fade_out_wait_ms = 100;
    this.ref_mask = React.createRef();
    this.is_show = false;
    this.fading_status = '';
    this.fade_out_handler = null;
  }

  shouldComponentUpdate(next_props) {
    if (next_props.mask && next_props.mask.length) {
      if (!this.is_show) {
        this.is_show = true
        this.fading_status = ''
        if (this.fade_out_handler) {
          clearTimeout(this.fade_out_handler)
        }
        this.ref_mask.current.classList.remove('fade_out')
        this.ref_mask.current.classList.add('show')
        window.setTimeout(_ => this.ref_mask.current.classList.add('fade_in'), 30)
      }
    } else {
      if (this.is_show) {
        this.is_show = false;
        if (!this.fading_status) {
          this.fading_status = 'wait';
          this.fade_out_handler = window.setTimeout(_ => {
            this.fading_status = 'fading';
            this.ref_mask.current.classList.remove('fade_in')
            this.ref_mask.current.classList.add('fade_out')
          }, this.fade_out_wait_ms);
        }
      }
    }
    return false;
  }

  on_transitionend() {
    if (this.fading_status === 'fading') {
      this.fading_status = '';
      this.ref_mask.current.classList.remove('fade_out', 'show')
    }
  }

  componentDidMount() {
    this.ref_mask.current.addEventListener('transitionend', this.on_transitionend.bind(this))
  }

  render() {
    let show = true;
    let render_obj = null
    if (show) {
      render_obj = (
        <section className="fe_mask" ref={this.ref_mask}>
        </section>
      )
    }

    return render_obj

  }
}

export default connect(
  msp
)(Mask)
