import _ from 'lodash'


// getter_pre = function( key,state_obj,_this ){
//   if( key.endsWith('_id') && !_.has( state_obj,key ) ){
//    // role_id -> role.id
//     key = key.slice( 0,-3 ) + '.id'
//   }else if( key.endsWith('_ids') && !_.has( state_obj,key ) ){
//     // rold_ids -> roles[id]
//     key = key.slice( 0,-4 ) + 's'
//     return _.get( state_obj,key,[] ).map( i => i.id )
//   }
//   return _.has( state_obj,key ) ? _.get( state_obj,key ) : ''
// }

// setter_pre = function(){

// }


const sort_by_ids = function (da, ids) {
  const id_idx_map = _.fromPairs(da.map((item, idx) => {
    return [item.id, idx]
  }))
  return ids.map(id => da[id_idx_map[id]]);
}


export default {
  sort_by_ids,
}
