import React from 'react';
import { connect } from 'react-redux';

import MenuAction from '../redux/actions/menu.js'

import _t from '../i14n/index.js'

import FAI from '../fontawesome.js'
import { Link } from "react-router-dom";

import LoadingDots from '../widgets/LoadingDots.jsx'

function msp(state) {
  return {
    menu:state.menu
  };
}

function mdp(dispatch) {
  return {
    toggle_child:( name,is_open ) => {
      dispatch(MenuAction.toggle_child( name,is_open ))  
    }
  }
}

export class SideMenu extends React.Component {
  static propTypes = {
    // location: React.PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      menu_list:[]
    }
  }

  get_active_l0( path,tree ){
    path = path.startsWith('/') ? path : `/${path}`
    for( let i1=0; i1<tree.length; i1++ ){
      if( tree[i1].path == path ){
        return tree[i1]
      }else{
        if( tree[i1].childs && tree[i1].childs.length ){
          for( let i2=0; i2<tree[i1].childs.length; i2++ ){
            if( tree[i1].childs[i2].path == path ){
              return tree[i1]
            }else{
              if( tree[i1].childs[i2].childs && tree[i1].childs[i2].childs.length ){
                for( let i3=0; i3<tree[i1].childs[i2].childs.length; i3++ ){
                  if( tree[i1].childs[i2].childs[i3].path == path ){
                    return tree[i1]
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  render() {
    const tree = this.props.menu.tree
    const route_path = this.props.location.pathname
    const route_key = route_path.substr( '/backend/'.length );
    
    const active_lv0 = this.get_active_l0( route_key,tree )

    let content = <section className="side_menu"><LoadingDots /></section>
    if( !this.props.menu.in_ajax ){
      content = (
        <section className="side_menu">
          <ul className="ul_0">
          { tree.map( i0 => {
            const i0_has_child = !!i0.childs.length

            let i0_path = '/backend'+i0.path
            i0_path = i0.redirect ? '/backend'+i0.redirect : i0_path

            const i0_is_active = active_lv0 && ( i0.path == active_lv0.path )

            const open = typeof i0['open'] == 'undefined' ? ( ( active_lv0 && active_lv0.key == i0.key ) ? true : false ) : i0['open']

            return (
              <li className={'li_'+i0.depth + ( i0_has_child ? ' has_child' : '' ) + ( open ? ' open' : '' ) + ( i0_is_active ? ' active' : '' ) } key={i0.name}>
                <Link className={'a_'+i0.depth} to={i0_path}  onClick={ () => { this.props.toggle_child(i0.name,open) } }>
                  <i><FAI icon={i0.icon || 'cubes' } /></i>
                  <h5>
                    {i0.name}
                  </h5>
                </Link>
                { !i0_has_child ? null : (
                  <ul className="ul_1">
                    {
                      i0.childs.map( i1 => {
                        const i1_has_child = !!i1.childs.length
                        // console.log(i1.name + ' ' + i1.depth);

                        let i1_path = '/backend'+i1.path
                        const i1_is_active = i1_path == route_path
                        i1_path = i1.redirect ? '/backend'+i1.redirect : i1_path
                        
                        return (
                          <li className={'li_'+i1.depth + ( i1_is_active ? ' active' : '') } key={i1.key}>
                            <Link className={'a_'+i1.depth} to={i1_path}>
                              <i><FAI icon={i1.icon || 'cubes' } /></i>
                              <h5>
                                {i1.name}
                              </h5>
                            </Link>
                            <ul className="ul_2">

                              {
                                i1.childs.map( i2 => {

                                  let i2_path = '/backend'+i2.path
                                  const i2_is_active = i2_path == route_path
                                  i2_path = i2.redirect ? '/backend'+i2.redirect : i2_path
                                  
                                  return (
                                    <li className={'li_'+i2.depth + ( i2_is_active ? ' active' : '') } key={i2.key}>
                                      <Link className={'a_'+i2.depth} to={i2_path}>
                                        <i><FAI icon={i2.icon || 'cubes' } /></i>
                                        <h5>
                                          {i2.name}
                                        </h5>
                                      </Link>
                                    </li>
                                  )
                                } )
                              }


                            </ul>
                          </li>
                        )
                      } )
                    }
                  </ul>
                )}
              </li>
            )
          } ) }
          </ul>
        </section>
      );
    }



    return content
  }
}

export default connect(
  msp,mdp
)(SideMenu)
