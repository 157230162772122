import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition,TransitionGroup } from 'react-transition-group';
import FAI from '../fontawesome.js'

function msp(state) {
  return {
    toast:state.toast
  };
}

function mdp(dispatch) {
  return {
  }
}

export class Toast extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      toast_list:[]
    }

    this.stay_time = 3000 //ms
    this.toast_timeout = {}
  }

  update_toast_list(){
    const new_toast_list = []
    const min_time = Date.now() - this.stay_time
    this.props.toast.forEach( item => {
      if( item.time > min_time ){
        new_toast_list.push( item )
      }

      if( typeof this.toast_timeout[item.id] == 'undefined' ){
        this.toast_timeout[item.id] = window.setTimeout( ()=>{
          this.update_toast_list()
        },this.stay_time );
      }
      
    } )

    const cur_ids = this.state.toast_list.map( item => item.id ).sort().join(',')
    const new_ids = new_toast_list.map( item => item.id ).sort().join(',')
    if( cur_ids !== new_ids ){
      this.setState({
        ...this.state,
        toast_list:new_toast_list
      })
    }

  }

  componentDidUpdate( prev ){
    this.update_toast_list()
  }

  render() {
    return (
      <TransitionGroup className="toast">
        { this.state.toast_list.map( (item) => {
          let Icon = <FAI icon="info-circle" />
          switch( item.type ){
            case 'error':
              Icon = <FAI icon="exclamation-circle" />
              break
            case 'warning':
              Icon = <FAI icon="exclamation-triangle" />
              break
            case 'stop':
              Icon = <FAI icon="times-circle" />
              break
            case 'success':
              Icon = <FAI icon="check-circle" />
              break
            default:
          }

          return (
            <CSSTransition key={item.id} timeout={300} className={'item '+item.type}>
              <span>
                <i>{Icon}</i>
                <span>{item.title}</span>
              </span>
            </CSSTransition>
          )
        } ) }
      </TransitionGroup>
    );
  }
}

export default connect(
  msp,mdp
)(Toast)
