import API from '../../../../api.js'


export default {

  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'role_id':{
    tab:'基本信息',
    label:'角色',
    type:'select',
    type_params:{
      options:function( _this ){
        return API.get_object_options('role')
      }
    },
    require: true, //是否必填
  },
  'mobile':{
    tab:'基本信息',
    label:'手机',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'email':{
    tab:'基本信息',
    label:'邮箱',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'wechat':{
    tab:'基本信息',
    label:'微信',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'qq':{
    tab:'基本信息',
    label:'QQ',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'permission_ids':{
    tab:'基本信息',
    label:'权限',
    type:'checkboxes',
    type_params:{
      options:function( _this ){
        return API.get_object_options('permission',{group_by:'group'})
      }
    },
    require: false, //是否必填
  },

}
