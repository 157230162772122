import React from 'react';
import _ from 'lodash';
import ErrorInfo from './ErrorInfo.jsx';
import Button from './Button.jsx';
import FAI from '../../fontawesome.js'
import LoadingDots from '../LoadingDots.jsx';
import jsonFormat from 'json-format'

export default class JsonEditor extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.real_name = this.props.name
    this.temp_name = `__t_${this.real_name}`

    this.json_format_config = {type:'space'}
  }

  componentDidMount() {
    this.init_temp_value()
  }

  componentDidUpdate(prevProps, prevState) {
    this.init_temp_value()
  }

  json_format(json){
    if( typeof json === 'undefined' ) return '';
    if( typeof json === 'object' && _.isEmpty(json) ) return '';
    if( typeof json === 'string' && json === '' ) return '';
    return jsonFormat(json,this.json_format_config)
  }

  init_temp_value(){
    this.real_name = this.props.name
    this.temp_name = `__t_${this.real_name}`
    const temp_val = this.props.getter(this.temp_name);
    if( typeof temp_val === 'undefined' ){
      const json = this.props.getter(this.real_name);
      this.props.setter( this.temp_name, this.json_format(json) );
    }
  }

  on_change(evt){
    const value = evt.target.value;
    this.props.setter( this.temp_name,value, err => {
      if( !err ){
        try{
          let json_value = value
          if( value !== '' ){
            json_value = JSON.parse(value);
          }
          this.props.setter( this.real_name,json_value )
        }catch(e){ console.log(e) }
      }
    } )
  }

  render() {

    let _props = { ...this.props };

    const has_err = !!_props.error
    let error = ''
    if (has_err) {
      error = _props.error
      delete _props.error
    }

    let className = (_props.className || '') + ' fe_textarea fe_json' + (has_err ? ' has_error' : '')
    delete _props.className

    let Icon = null
    if (_props.icon) {
      Icon = <i><FAI icon={_props.icon} /></i>
      className = className + ' has_icon'
      delete _props.icon
    }

    _props.value = this.props.getter( this.temp_name )
    _props.onChange = this.on_change.bind(this)

    _props = _.omit( _props,['name','getter','setter'] )
    return (
      <label className={className}>
        <textarea {..._props} />
        {Icon}
        { has_err ? <ErrorInfo error={error}/> : null }
      </label>
    );

  }
}
