import API from '../../../../api.js'

export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'texture':{
    tab:'基本信息',
    label:'材质',
    type:'checkboxes',
    type_params:{
      options:[
        {label:'纸',value:'1'},
        {label:'金属',value:'2'},
      ]
    },
    require: false, //是否必填
  },
  'color':{
    tab:'基本信息',
    label:'颜色',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
}
