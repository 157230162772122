import React from 'react';
import _ from 'lodash';
import FAI from '../fontawesome.js'
import { Button } from './form'
import list_templates from '../list_templates'

const _cn = 'MiniListBuilder';

export default class MiniListBuilder extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.list_ref = React.createRef()
  }

  componentDidMount() {
    console.log(`${_cn} - componentDidMount` )

    //把bind过的函数单独存储，不然无法removeEventListener
    this.func_drag_start = this.drag_start.bind(this);
    this.func_drag_move = this.drag_move.bind(this);
    this.func_drag_stop = this.drag_stop.bind(this);

    document.body.addEventListener('mousedown', this.func_drag_start)
    document.body.addEventListener('mousemove', this.func_drag_move)
    document.body.addEventListener('mouseup', this.func_drag_stop)
  }

  componentDidUpdate() {
    console.log(`${_cn} - componentDidUpdate`)
  }

  componentWillUnmount() {
    console.log(`${_cn} - componentWillUnmount`)
    document.body.removeEventListener('mousedown', this.func_drag_start)
    document.body.removeEventListener('mousemove', this.func_drag_move)
    document.body.removeEventListener('mouseup', this.func_drag_stop)
  }

  shouldComponentUpdate(nextProps, nextState){
    console.log(`${_cn} - componentShouldUpdate`)
    const this_ids = this.props.list.map( i => i.id )
    const next_ids = nextProps.list.map( i => i.id )
    return !_.isEqual( this_ids,next_ids )
  }

  get_list_item() {
    if (this.list_ref) {
      return this.list_ref.current.querySelectorAll('.list_item');
    } else {
      return null;
    }
  }

  is_list_item(el) {
    const list_items = this.get_list_item();
    if (list_items) {
      for (let i = 0; i < list_items.length; i++) {
        if (list_items[i] === el) {
          return el;
        }
      }
    }
    return false;
  }


  draw_block(x, y, w, h) {
    let el = document.querySelector('.__mini_list_drag_block');
    if (!el) {
      el = document.createElement('div');
      el.classList.add('__mini_list_drag_block');
      document.body.appendChild(el);
    }
    if (typeof x === 'undefined') {
      el.style.display = 'none';
    } else {
      el.style.display = 'block';
      el.style.left = x + 'px';
      el.style.top = y + 'px';
      if (typeof w !== 'undefined') {
        el.style.width = w + 'px';
        el.style.height = h + 'px';
      }
    }
  }

  drag_start(e) {
    const el_item = this.is_list_item(e.target.closest('.list_item'));
    const el_no_drag = e.target.closest('.no_drag');
    if (el_item && !el_no_drag) {
      // console.log('drag_start');
      e.preventDefault();
      this.set_ids_of_list_items();
      this.enable_drag = true;
      const el_rect = el_item.getBoundingClientRect();
      this.draw_block(el_rect.left, el_rect.top, el_rect.width, el_rect.height);
      this.mouse_origin = { x: e.clientX, y: e.clientY }
      this.block_origin = { x: el_rect.left, y: el_rect.top }
      this.drag_item = el_item;
      this.drag_rect = el_rect;
      this.drag_id = parseInt(el_item.dataset.id);
      this.drag_item.classList.add('on_drag');
      this.all_items = document.querySelectorAll('.list_item');
    }
  }

  drag_stop(e) {
    if (this.enable_drag) {
      // console.log('drag_stop')
      this.drag_item.classList.remove('on_drag');
      this.enable_drag = false;
      this.draw_block();

      if (typeof this.props.on_change == 'function') {
        let ids = []
        this.list_ref.current.querySelectorAll('.list_item').forEach(i => {
          ids.push(parseInt(i.dataset.id))
        })
        const list_ids = this.props.list.map(i => i.id);
        if (!_.isEqual(ids, list_ids)) {
          this.props.on_change(ids)
        }
      }

    }
  }

  drag_move(e) {
    if (this.enable_drag) {
      // console.log('drag_move')
      let diff_x = e.clientX - this.mouse_origin.x;
      let diff_y = e.clientY - this.mouse_origin.y;
      let new_x = this.block_origin.x + diff_x;
      let new_y = this.block_origin.y + diff_y;
      this.draw_block(new_x, new_y);
      for (let i = 0; i < this.all_items.length; i++) {
        const item = this.all_items[i];
        const rect = item.getBoundingClientRect();
        const id = parseInt(item.dataset.id);
        const mid_y = new_y + this.drag_rect.height / 2;
        if (mid_y > rect.top && (mid_y - rect.top) < rect.height) {
          if (this.drag_id !== id) {
            this.on_order_change(this.drag_item, item);
          }
          break;
        }
      }
    }
  }

  on_order_change(pick, place) {

    const pick_idx = pick.dataset.idx;
    const place_idx = place.dataset.idx;
    const idx_diff = pick_idx - place_idx;

    if (idx_diff > 0) {
      //move up
      this.list_ref.current.insertBefore(pick, place);
    } else {
      //move down
      const place_next = place.nextSibling
      if (place_next === null) {
        this.list_ref.current.appendChild(pick);
      } else {
        this.list_ref.current.insertBefore(pick, place_next);
      }
    }

    this.set_ids_of_list_items();

  }

  set_ids_of_list_items() {
    this.list_ref.current.querySelectorAll('.list_item').forEach((i, idx) => {
      i.dataset.idx = idx;
    })
  }

  build_table(list, fields) {

    const fields_da = []

    let max_width = 0
    for (let k in fields) {
      fields_da.push({
        key: k,
        ...fields[k]
      })
      if (typeof fields[k].width === 'number') {
        max_width += fields[k].width
      }
    }

    return (
      <table className="list_table mini">
        <colgroup>
          {fields_da.map( c => {
            let col_w = c.width
            if( typeof c.width === 'number' ){
              col_w = parseInt( c.width/max_width * 100 ) + '%'
            }
            return (<col key={c.key} width={col_w} />)
          } )}
          <col width="60" />
        </colgroup>
        <thead>
          <tr>
            {fields_da.map( c => {
              return (<th key={c.key} className={'col_' + c.key}>{c.label}</th>)
            } )}
            <th className="col__ops">操作</th>
          </tr>
        </thead>
        <tbody ref={this.list_ref}>
          { list.map( i => {
            return (
              <tr key={i.id} className="list_item" data-id={i.id}>
                {fields_da.map( c => {
                  const col_v = c.value
                  let i_v 
                  if( typeof col_v === 'string' ){
                    i_v = _.get(i,col_v) || ''
                  }else if( typeof col_v === 'function' ){
                    i_v = col_v( i )
                  }
                  return (<td className={"col_" + c.key} key={c.key}>{i_v}</td>)
                } )}
                <td className="col__ops no_wrap no_drag">
                  <Button className="btn delete sm" icon="times" onClick={ _ => { this.props.on_delete(i) }}></Button>
                  <Button className="btn primary sm" icon="edit" onClick={ _ => { this.props.on_edit(i) }}></Button>
                </td>
              </tr>
              )
          } ) }
        </tbody>
      </table>
    );

  }


  build_template(list, tpl_builder) {
    console.log('build_template')
    console.log(list)

    return (
      <div className="list_template" ref={this.list_ref}>
        { list.map( i => {
          const actions = {
            on_delete:_ => { this.props.on_delete(i) },
            on_edit: _ => { this.props.on_edit(i) },
          }
          {/*return null;*/}
          const item = tpl_builder( i,actions,this.props,this.state )
          return <div key={i.id} className="list_item" data-id={i.id}>{item}</div>
        } ) }
      </div>
    );
  }


  render() {
    console.log(`${_cn} - render`)

    let mode = 'table'

    let tpl = _.get(this.props, 'template')
    if (tpl) {
      tpl = _.get(list_templates, tpl)
      if (tpl) {
        mode = 'tpl';
      }
    }

    if (mode === 'table') {
      return this.build_table(this.props.list, this.props.fields)
    } else {
      return this.build_template(this.props.list, tpl)
    }

  }
}
