import API from '../../../../api.js'


export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{
      readOnly:true,
    },
    require: true, //是否必填
  },
  'ext':{
    tab:'基本信息',
    label:'扩展名',
    type:'text',
    type_params:{
      readOnly:true,
    },
    require: true, //是否必填
  },
  'mime_type':{
    tab:'基本信息',
    label:'MIME类型',
    type:'text',
    type_params:{
      readOnly:true,
    },
    require: false, //是否必填
  },
  'size':{
    tab:'基本信息',
    label:'大小',
    type:'text',
    type_params:{
      readOnly:true,
    },
    require: false, //是否必填
  },
  __:{
    show_submit:false
  }
}
