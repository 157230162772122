import React from 'react'
import _ from 'lodash'
import { Button } from '../widgets/form'

export default ( item,actions,props,states ) => {
  
  const on_edit = _.get( actions, 'on_edit', null );
  const on_delete = _.get( actions, 'on_delete', null );


  return <React.Fragment>
    <h2>
      <span className="text">{item.name}</span>
      <span className="actions no_drag">
        { on_edit && <Button className="btn_icon primary" icon="edit" onClick={ on_edit }></Button> }
        { on_delete && <Button className="btn_icon delete" icon="times" onClick={ on_delete }></Button> }
      </span>
    </h2>
    <div className="row">
      <div className="item">
        <h3>key:</h3>
        <div className="cont">{item.key}</div>
      </div>
      <div className="item">
        <h3>key:</h3>
        <div className="cont">{item.key}</div>
      </div>
    </div>
  </React.Fragment>
}
