import React, { Component } from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import store from './store.js'
import './App.scss';
// import Layout from './layouts/Normal';

import Login from './layouts/Login'
import Backend from './layouts/Backend'
import Playground from './layouts/Playground'

import Liburary from './layouts/Liburary'

import Toast from './widgets/Toast.jsx';
import Dialog from './widgets/Dialog.jsx';
import Mask from './widgets/Mask.jsx';

import UserAction from './redux/actions/user.js'

//需要login的Route
function PrivateRoute({ component: Component, ...rest }) {
  const render = function(props){
    const state = store.getState()
    if( state.user.is_login ){
      return ( <Component {...props} /> )
    }else{
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  }
  return (
    <Route {...rest} render={render} />
  );
}

const from_path = window.location.pathname + window.location.search + window.location.hash

class App extends Component {
  componentDidMount(){
    //自动登录
    // store.dispatch( UserAction.auto_login(from_path) )
  }
  componentDidUpdate(){
    console.log('APP UPDATE');
  }


  // <PrivateRoute path="/backend" component={Backend} />
  // <Route path="/playground" component={Playground} />
  // <Route exact path="/" component={Login} />
  // <Route path="/" component={Playground} />
  // <Route render={()=> <Redirect to="/crac_test" /> }/>

  render() {

    console.log(this.state)

    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Switch>
              <Route path="/liburary" component={Liburary} />
              <Route path="/favourite" component={Liburary} />
              <Route path="/history" component={Liburary} />
              <Route path="/test" component={Liburary} />
              <Route render={ _ => <Redirect to="/liburary" /> }/>
            </Switch>
            <Toast />
          </div>
        </Router>
        <Mask />
        <Dialog />
      </Provider>
    );
  }
}


export default App;
