import API from '../../../../api.js'

export default {
  'name': {
    tab: '基本信息',
    label: '名称',
    type: 'text',
    type_params: {},
    require: true, //是否必填
  },
  'func': {
    tab: '基本信息',
    label: 'Func',
    type: 'text',
    type_params: {},
    require: true, //是否必填
  },
  'obj': {
    tab: '基本信息',
    label: 'Object',
    type: 'text',
    type_params: {},
    require: false, //是否必填
  },
  'group': {
    tab: '基本信息',
    label: 'Group',
    type: 'text',
    type_params: {},
    require: false, //是否必填
  },
}
