import _ from 'lodash';

export default ( form_obj,key,params={} ) => ( new Promise( (rsl,rej) => {
  const temp_name = `__t_${key}`
  const val = _.get( form_obj,temp_name,'' );
  if( _.trim(val) === '' ) rsl(val);
  try{
    JSON.parse(val);
    rsl(val);
  }catch(e){
    rej({err_code:'json_format_error'});
  }
} ) );