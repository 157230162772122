const push = function (obj) {
  return dispatch => {
    dispatch({ type: 'MASK_PUSH', payload: obj })
  }
}

const pop = function (obj) {
  return dispatch => {
    dispatch({ type: 'MASK_POP', payload: obj })
  }
}

export default { push, pop }
