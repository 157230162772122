export default {
  'id': {
    label: 'id',
    width: '50px',
    value: 'id',
    sort_id: 'id',
  },
  'name': {
    label: '名称',
    width: 10,
    value: 'name',
    sort_id: 'name',
  },
  'func': {
    label: 'func',
    width: 10,
    value: 'func',
    sort_id: 'func',
  },
  'obj': {
    label: 'obj',
    width: 10,
    value: 'obj',
    sort_id: 'obj',
  },
  'group': {
    label: 'group',
    width: 10,
    value: 'group',
    sort_id: 'group',
  },
}
