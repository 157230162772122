import API from '../../../../api.js'

export default {
  'name':{
    tab:'基本信息',
    label:'名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'note':{
    tab:'基本信息',
    label:'备注',
    type:'textarea',
    type_params:{},
    require: false, //是否必填
  },
  'client_name':{
    tab:'客户信息',
    label:'客户名称',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'client_tel':{
    tab:'客户信息',
    label:'电话',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'client_address':{
    tab:'客户信息',
    label:'地址',
    type:'text',
    type_params:{},
    require: true, //是否必填
  },
  'client_email':{
    tab:'客户信息',
    label:'邮箱',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'client_wechat':{
    tab:'客户信息',
    label:'微信',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'client_qq':{
    tab:'客户信息',
    label:'QQ',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'product':{
    tab:'产品信息',
    label:'产品清单',
    type:'select',
    type_params:{
      options:[]
    },
    require: false, //是否必填
  },
  'suite':{
    tab:'产品信息',
    label:'套装清单',
    type:'select',
    type_params:{
      // options:[]
    },
    require: false, //是否必填
  },
  'client_qq':{
    tab:'财务信息',
    label:'收入',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'client_qq':{
    tab:'财务信息',
    label:'支出',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'client_qq':{
    tab:'财务信息',
    label:'税率',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'client_qq':{
    tab:'财务信息',
    label:'利润',
    type:'text',
    type_params:{},
    require: false, //是否必填
  },
  'status':{
    tab:'订单记录',
    label:'订单记录',
    type:'textarea',
    type_params:{},
    require: false, //是否必填
  },
}
