import React from 'react';
import { connect } from 'react-redux';
import UserAction from '../redux/actions/user.js'
import DialogAction from '../redux/actions/dialog.js'
import { Redirect } from 'react-router-dom'

function msp(state) {
  return {
    user: state.user
  };
}

function mdp(dispatch) {
  return {
    logout: () => {
      dispatch(UserAction.logout())
    },
    show_dialog: config => {
      dispatch(DialogAction.show(config))
    }
  }
}

export class Logout extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  logout() {
    this.props.show_dialog({
      type: 'dialog',
      title: '退出登录',
      content: '您确定要退出登录吗？',
      on_confirm: close => {
        console.log( 'logout on confirm' )
        console.log( close )
        this.props.logout()
        close()
      },
      on_cancel: true,
    })
  }

  render() {

    let el = null
    if (this.props.user.is_login) {
      el = React.cloneElement(this.props.children, {
        onClick: this.logout.bind(this),
        in_ajax: this.props.user.in_ajax
      })
    } else {
      el = <Redirect to="/" />
    }

    return ( <
      >
      { el } < />
    );
  }
}

export default connect(
  msp, mdp
)(Logout)
