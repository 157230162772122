import API from '../../api.js'
import ToastAction from './toast.js'

const get_menu = function( force_reload = false ){
  return (dispatch,getState) => {
    const state = getState()

    if( !state.menu.loaded || force_reload ){
      dispatch( { type:'MENU_GET'} )
      API( 'menu.show',{ id:'system' } ).then( ( result ) => {
        if( result.data.status ){
          dispatch( { type:'MENU_GET_SUCCESS',payload:{...result.data.data} } )
        }else{
          dispatch( { type:'MENU_GET_FAIL',payload:result.data.error } )
          dispatch( ToastAction.toast( result.data.error.description,'stop' ) )
        }
      } ).catch( e => {
          dispatch( { type:'MENU_GET_FAIL',payload:e } )
          dispatch( ToastAction.toast( '网络或服务器错误。','error' ) )
      } )
    }

  }
}

const toggle_child = function( name,is_open ){
  return (dispatch,getState) => {
    dispatch( { type:'MENU_TOGGLE',payload:{ name,is_open }} )
  }
}

export default { get_menu,toggle_child }
