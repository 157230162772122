import React from 'react';
import _ from 'lodash';
import ReactSelect from 'react-select';
import ErrorInfo from './ErrorInfo.jsx';
import LoadingDots from '../LoadingDots.jsx';

export default class Select extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  // constructor(props) {
  //   super(props);

  //   if( typeof props.options == 'function' ){
  //     props.options().then( data => {
  //       this.setState({
  //         options:data,
  //         value:this.get_opt_of_value( this.props.getter( this.props.name ),data )
  //       })
  //     } )
  //   }else{
  //     this.state = {
  //       options:_.get( props,'options',[] ),
  //       value:this.get_opt_of_value( this.props.getter( this.props.name ),_.get( props,'options',[] ) )
  //     }
  //   }

  //   if( !this.state ){
  //     this.state = {
  //       options:[],
  //       value:0,
  //     }
  //   }

  // }

  constructor(props) {
    super(props);

    let options = [];
    if (_.isArray(props.options)) {
      options = props.options
    }

    this.state = {
      options,
      value: props.getter(props.name),
      in_ajax: false,
      error: '',
    }

  }

  componentDidMount() {
    this.get_remote_options();
  }


  // componentDidUpdate( prevProps, prevState ){
  //   const value = this.get_opt_of_value( this.props.getter( this.props.name ),this.state.options )
  //   if( prevState.value != value  ){
  //     this.setState({
  //       value
  //     })
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    const value = this.props.getter(this.props.name)
    if (this.state.value !== value) {
      this.setState({
        value
      })
    }
  }


  get_remote_options() {

    if (typeof this.props.options === 'function' && !this.state.in_ajax) {
      this.setState({
        in_ajax: true,
        error: '',
      })
      this.props.options().then(data => {
        this.setState({
          options: data,
          in_ajax: false,
        })
      }).catch(e => {
        this.setState({
          error: e ? e.message : 'Unknow Error',
          in_ajax: false,
        })
      })
    }

  }

  onchange = function (item){
    this.props.setter( this.props.name,item.value )
  }

  get_opt_of_value( value,options ){
    const fl_options = options.filter( i => i.value == value );
    return fl_options[0]  
  }

  render() {

    const _props = {...this.props}
    delete _props.options

    const has_err = !!_props.error
    let error = ''
    if( has_err ){
      error = _props.error
      delete _props.error
    }

    let className = (_props.className || '') + ' fe_select' + ( has_err ? ' has_error' : '' )
    delete _props.className

    let StatusComponenet = null;
    let grouped = false;
    let options = [];

    if (this.state.in_ajax) {
      StatusComponenet = <span className="in-ajax"><LoadingDots/> 数据获取中</span>
    } else {
      if (!this.state.error) {
        if (_.has(this, 'state.options') && _.isArray(this.state.options)) {
          if (this.state.options.length) {
            if (_.has(this.state.options[0], 'group')) {
              grouped = true;
              let grouped_options = {};
              this.state.options.map(i => {
                if (!_.has(grouped_options, i.group)) {
                  grouped_options[i.group] = {
                    label: i.group,
                    options: [],
                  }
                }
                grouped_options[i.group].options.push(i)
              })
              options = _.values(grouped_options)
            } else {
              options = this.state.options
            }
          } else {
            StatusComponenet = (<span className="no-options">还没有选项，请先添加选项。</span>);
          }
        } else {
          StatusComponenet = (<span className="no-options">选项不存在，请联系管理员。</span>);
        }
      }
    }

    const value = this.get_opt_of_value( this.state.value,this.state.options )
    
    const groupStyles = {
      fontSize:'0.14rem',
      fontWeight:'700',
      padding:'0 0 0.1rem',
      margin:'0',
      borderBottom:'dashed 1px #ccc',
    };

    const group_label = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
      </div>
    );

    return (
      <label className={className}>
        { !this.state.in_ajax && options && !this.state.error && (
          <ReactSelect
            value={value}
            options={options}
            onChange={this.onchange.bind(this)}
            className="al-react-select"
            classNamePrefix="al"
            noOptionsMessage={function(){ return '没有选项' }}
            loadingMessage={function(){ return '正在加载...' }}
            placeholder="请选择"
            formatGroupLabel={group_label}
          />
        ) }

        { ( StatusComponenet || this.state.error ) && (
          <div className="info">
            {StatusComponenet}
            { this.state.error && (
              <span className="error">出现错误，请<span className="a_like"
                onClick={this.get_remote_options.bind(this)}>重试</span>
                &nbsp;&nbsp;
                ({this.state.error})</span>
            ) }
          </div>
        ) }

        { has_err ? <ErrorInfo error={error}/> : null }
      </label>
    );
  }
}
