import API from '../../../../api.js'

export default {
  'name[~]': {
    label: '名称',
    type: 'text',
    type_params: {},
  },
  'func[~]': {
    label: 'Func',
    type: 'text',
    type_params: {},
  },
  'obj[~]': {
    label: 'Object',
    type: 'text',
    type_params: {},
  },
  'group[~]': {
    label: 'Group',
    type: 'text',
    type_params: {},
  },
}
