import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import LoadingDots from '../../widgets/LoadingDots.jsx'

import FAI from '../../fontawesome.js'

import API from '../../api.js'

import List from './List.jsx'
import Detail from './Detail.jsx'

function msp(state) {
  return {
    test: state.test
    // object: state.object
  };
}

function mdp(dispatch) {
  return {
    // list: function (object, criteria = [], page = 1, size = 10, order = {}, cb = null) {
    //   dispatch(ObjectAction.list(object, criteria, page, size, order, cb))
    // },
    // add: function (object, data, cb = null) {
    //   dispatch(ObjectAction.add(object, data, cb))
    // },
    // edit: function (object, id, data, cb = null) {
    //   dispatch(ObjectAction.edit(object, id, data, cb))
    // },
    // del: function (object, id, cb = null) {
    //   dispatch(ObjectAction.del(object, id, cb))
    // },
    // show_dialog: config => {
    //   dispatch(DialogAction.show(config))
    // }
  }
}

class Playground extends React.Component {
  static propTypes = {
    //name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.obj_key = 'test'

    this.state = {
      list:[],
      mode:'liburary'
    }
  }

  componentDidMount(){
    this.update_list();
  }


  componentDidUpdate(prevProps, prevState) {
    this.set_mode()

    if( prevState.mode !== this.state.mode ){
      this.update_list()
    }

  }

  set_mode(){
    this.setState( (prevState,props) => {
      const mode = props.location.pathname.slice(1)
      if( prevState.mode !== mode ){
        return { mode }
      }
    } )
  }


  update_list( params = {} ){

    if( this.state.mode === 'favourite' ){
      params.filters = [
        ['is_fav[=]',true]
      ]
    }


    API.obj_list( this.obj_key, params ).then( data => {
      this.setState( {
        list:data.content,
      } )
    } )
  }



  is_active( path ){
    const pathname = this.props.location.pathname;
    if( pathname === path ) return 'active';
  }

  toggle_fav( obj,evt ){
    API.obj_edit( this.obj_key,{ 
      id:obj.id,
      is_fav:!obj.is_fav,
    } ).then( data => {
      this.update_list()
    } )
  }

  toggle_change_note( obj ){

    this.setState( prevState => {
      let list = [ ...prevState.list ]

      list = list.map( i => {
        if( i.id === obj.id ){
          i._edit_mode = !i._edit_mode
          return i;
        }
        return i;
      } )
      return { list }

    } )

  }



  show_change_note( obj,evt ){
    this.toggle_change_note( obj )
  }

  change_note( obj,evt ){
    const val = evt.target.value
    this.setState( prevState => {
      let list = [ ...prevState.list ]

      list = list.map( i => {
        if( i.id === obj.id ){
          i.note = val
          return i;
        }
        return i;
      } )
      return { list }

    } )
  }
  
  on_change_note( obj,evt ){
    API.obj_edit( this.obj_key,{ 
      id:obj.id,
      note:obj.note,
    } ).then( data => {
      this.toggle_change_note( obj )
      this.update_list()
    } )
  }

  render() {

    let list_props = {
      list:this.state.list,
      full_mode: true,
      toggle_fav: this.toggle_fav.bind(this),
      show_change_note: this.show_change_note.bind(this),
      change_note: this.change_note.bind(this),
      on_change_note: this.on_change_note.bind(this),
    };



    return (

      <div className="wc">
        <nav className="main_nav">
          <Link to="/liburary" className={this.is_active('/liburary')}>题库</Link>
          <Link to="/favourite" className={this.is_active('/favourite')}>收藏</Link>
          <Link to="/history" className={this.is_active('/history')}>错题</Link>
          <Link to="/test" className={this.is_active('/test')}>测试</Link>
        </nav>
        <div className="body">
          <List {...list_props} />
        </div>
      </div>

    );
  }
}


export default connect(
  msp, mdp
)(Playground)
