import React from 'react';
import LoadingDots from '../LoadingDots.jsx'
import FAI from '../../fontawesome.js'

const Button = (props) => {
  
  const _props = {...props}

  _props.className = _props.className + ' fe_button'

  let Icon = null
  if( _props.icon ){
    Icon = <i><FAI icon={_props.icon} /></i>
   _props.className = _props.className + ' has_icon'
    delete _props.icon
  }

  let content = (
    <React.Fragment>
      {Icon}
      {_props.children}
    </React.Fragment>
  )

  if( _props.in_ajax ){
    _props.disabled = '1'
    _props.className = _props.className + ' disabled'
    content = (
      <LoadingDots />
    )
  }

  delete _props.in_ajax

  return (
    <button {..._props} >
      {content}
    </button>
  )
}

export default Button;