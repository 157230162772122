import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ErrorInfo from './ErrorInfo.jsx';
import { Button } from './index';
import FormBuilder from '../../widgets/FormBuilder.jsx'
import LoadingDots from '../../widgets/LoadingDots.jsx'
import MiniListBuilder from '../../widgets/MiniListBuilder.jsx'
import DialogAction from '../../redux/actions/dialog.js'
import { Dialog } from '../../widgets/form'
import API from '../../api'
import Utils from '../../utils'


function mdp(dispatch) {
  return {
    show_dialog: config => {
      dispatch(DialogAction.show(config))
    }
  }
}


class IdsObjects extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.value = props.getter(props.name) || [];

    this.object = ''

    this.state = {
      ids: this.value,
      form_mode: 'list', // list/add/edit
      list: [],
      add_obj: {},
      add_errors: {},
      edit_obj: {},
      edit_errors: {},
      form_in_ajax: false,
      list_in_ajax: false,
      error: '',
    };

  }

  componentDidMount() {
    this.update_list();
  }

  componentDidUpdate(prevProps, prevState) {
    const value = this.props.getter(this.props.name) || [];

    if (!_.isEqual(this.value, value)) {
      this.value = value;
      this.setState({ ids: value })
    }

    if (!_.isEqual(prevState.ids, this.state.ids)) {
      this.update_list()
    }
  }

  async update_list(force = false) {
    const val_ids = this.state.ids;
    const list_ids = this.state.list.map(i => i.id);
    let need_pull = false;

    for (let i = 0; i < val_ids.length; i++) {

      if (!~list_ids.indexOf(val_ids[i])) {
        need_pull = true;
        break;
      }
    }

    if (force || need_pull) {
      const filters = [
        ['id', this.state.ids]
      ];

      this.setState({
        list_in_ajax: true,
        error: ''
      })

      try {
        const result = await API.obj_list(this.props.object_key, { filters });

        let list = _.get(result, 'content', []);

        list = Utils.sort_by_ids(list, this.state.ids)
        this.setState({
          list,
        })

      } catch (e) {
        this.setState({
          error: e ? e.message : 'Unknow Error',
          list_in_ajax: false,
        })
      }

      this.setState({ list_in_ajax: false })

    } else {
      const list_kv = _.fromPairs(this.state.list.map(i => {
        return [i.id, i]
      }));
      const new_list = val_ids.map(id => list_kv[id])
      // console.log('new_list')
      // console.log(new_list)
      this.setState({
        list: new_list
      })
    }
  }

  show_add_form() {
    this.setState({
      form_mode: 'add'
    })
  }

  show_edit_form(item) {
    this.setState({
      form_mode: 'edit',
      edit_obj: item,
    })
  }

  close_form() {
    this.setState({
      form_mode: 'list'
    })
  }

  getter(mode) {
    return key => {
      if (mode === 'add') {
        return _.get(this.state.add_obj, key)
      } else {
        return _.get(this.state.edit_obj, key)
      }
    }
  }

  setter(mode) {
    return (key, value) => {
      let new_obj = mode === 'add' ? { ...this.state.add_obj } : { ...this.state.edit_obj }
      new_obj[key] = value

      if (mode === 'add') {
        this.setState({
          'add_obj': new_obj
        })
      } else {
        this.setState({
          'edit_obj': new_obj
        })
      }

      const fields = mode === 'add' ? this.props.add_fields : this.props.edit_fields;
      const error_key = mode === 'add' ? 'add_errors' : 'edit_errors';
      this.check_requires(new_obj, fields, error_key)

    }
  }

  set_error(mode, key, val) {
    const error_obj = mode === 'add' ? { ...this.state.add_errors } : { ...this.state.edit_errors };
    if (typeof val === 'undefined' || val === null) {
      delete error_obj[key];
    } else {
      error_obj[key] = val;
    }
    if (mode === 'add') {
      this.setState({ add_errors: error_obj });
    } else {
      this.setState({ edit_errors: error_obj });
    }
  }

  check_requires(form_obj, fields, error_key, full_mode = false) {
    let err_obj = {}
    let has_err = false

    if (full_mode) {
      for (let k in fields) {
        if (_.has(fields, k)) {
          const is_require = !!fields[k].require
          if (is_require) {
            if (!form_obj[k]) {
              has_err = true
              err_obj[k] = 'required'
            }
          }
        }
      }
    } else {
      for (let k in form_obj) {
        if (k == 'id' || k == '_id' || k == 'object') continue
        if (_.has(fields, k)) {
          const is_require = _.get(fields[k], 'require', false)
          if (is_require) {
            if (!form_obj[k]) {
              has_err = true
              err_obj[k] = 'required'
            }
          }
        }
      }
    }

    let new_o = {}
    if (has_err) {
      new_o[error_key] = err_obj
    } else {
      new_o[error_key] = {}
    }

    this.setState(new_o)
    return !has_err
  }

  clear_from_obj(mode) {
    switch (mode) {
      case 'add':
        this.setState({
          add_obj: {},
          add_errors: {},
        })
        break;
      case 'edit':
        this.setState({
          edit_obj: {},
          edit_errors: {},
        })
        break;
    }
  }

  on_close() {
    this.close_form();
  }

  async on_submit() {
    this.setState({ form_in_ajax: true })

    switch (this.state.form_mode) {
      case 'add':
        this.set_error('add', '__')
        try {
          const result = await API.obj_add(this.props.object_key, this.state.add_obj);
          if (result > 0) {
            const new_ids = [...this.state.ids];
            new_ids.push(result);
            this.props.setter(this.props.name, new_ids)
          }
        } catch (e) {
          this.set_error('add', '__', e.message)
        }
        this.close_form();
        this.clear_from_obj('add');
        // this.update_list();
        break;
      case 'edit':
        this.set_error('edit', '__')
        try {
          const result = await API.obj_edit(this.props.object_key, this.state.edit_obj);
          this.update_list(true)
        } catch (e) {
          this.set_error('edit', '__', e.message)
        }
        this.close_form();
        this.clear_from_obj('edit');
        // this.update_list();
        break;
    }

    this.setState({ form_in_ajax: false })

  }

  on_delete(item) {
    this.props.show_dialog({
      type: 'dialog',
      title: '确认删除',
      content: `您确定要删除${this.props.object_label} "${item.name}" 吗？`,
      on_confirm: close => {
        let new_ids = _.clone(this.state.ids)
        new_ids = new_ids.filter(id => id !== item.id)
        if (!_.isEqual(new_ids, this.state.ids)) {
          this.props.setter(this.props.name, new_ids)
        }
        close()
      },
      on_cancel: true,
    })

  }

  on_edit(item) {
    this.show_edit_form(item)
  }

  on_list_change(ids) {
    if (!_.isEqual(ids, this.state.ids)) {
      this.props.setter(this.props.name, ids)
    }
  }

  on_clear_list() {
    this.props.show_dialog({
      type: 'dialog',
      title: '确认清空',
      content: `您确定要清空所有${this.props.object_label}吗？`,
      on_confirm: close => {
        this.props.setter(this.props.name, [])
        close()
      },
      on_cancel: true,
    })
  }


  render() {

    const _props = { ...this.props }
    delete _props.options

    const has_err = !!_props.error
    let error = ''
    if (has_err) {
      error = _props.error
      delete _props.error
    }

    let className = (_props.className || '') + ' fe_ids_objects' + (has_err ? ' has_error' : '')
    delete _props.className

    let StatusComponenet = null;
    let grouped = false;
    let options = [];

    let object_list = null;

    if (this.state.list_in_ajax) {
      StatusComponenet = <span className="in-ajax"><LoadingDots/> 数据获取中</span>
    } else {
      if (!this.state.error) {
        if (this.state.list.length) {
          object_list = (<MiniListBuilder 
            fields={this.props.list_fields}
            template={this.props.list_template}
            list={this.state.list}
            on_delete={this.on_delete.bind(this)}
            on_edit={this.on_edit.bind(this)}
            on_change={this.on_list_change.bind(this)}
          />);
        } else {
          StatusComponenet = (<span className="no-options">还没有{this.props.object_label}，请先添加{this.props.object_label}。</span>);
        }
      }
    }

    return (
      <div className={className}>

        { this.state.form_mode === 'list' &&
          (<div className="list_wrap">
            <div className="btns">
              <Button className="btn create sm" onClick={this.show_add_form.bind(this)}>添加{this.props.object_label}</Button>
              <Button className="btn delete sm" onClick={this.on_clear_list.bind(this)}>清空{this.props.object_label}</Button>
              <Button className="btn normal sm" onClick={this.update_list.bind(this)}>刷新列表</Button>
            </div>
            {object_list}
          </div>)
        }

        { this.state.form_mode === 'add' ? (
          <FormBuilder 
            _this={this}
            obj_label={this.props.object_label}
            fields={this.props.add_fields}
            errors={this.state.add_errors}
            mode="add"
            setter={this.setter('add')}
            getter={this.getter('add')}
            on_close={this.on_close.bind(this)}
            on_submit={this.on_submit.bind(this)}
            show_close={true}
            in_ajax={this.state.form_in_ajax}
          />
          ) : null }

        { this.state.form_mode === 'edit' ? (
          <FormBuilder 
            _this={this}
            obj_label={this.props.object_label}
            fields={this.props.edit_fields}
            errors={this.state.edit_errors}
            mode="edit"
            setter={this.setter('edit')}
            getter={this.getter('edit')}
            on_close={this.on_close.bind(this)}
            on_submit={this.on_submit.bind(this)}
            show_close={true}
            in_ajax={this.state.form_in_ajax}
          />
          ) : null }

        { ( StatusComponenet || this.state.error ) && (
          <div className="info">
            {StatusComponenet}
            { this.state.error && (
              <span className="error">出现错误，请<span className="a_like"
                onClick={this.update_list.bind(this)}>重试</span>
                &nbsp;&nbsp;
                ({this.state.error})</span>
            ) }
          </div>
        ) }

        { has_err ? <ErrorInfo error={error}/> : null }
        
      </div>
    );
  }
}

export default connect(
  null, mdp
)(IdsObjects)
