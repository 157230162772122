export default ( state = {}, action )=>{
  switch ( action.type ){
    case 'USER_LOGIN':
      return {
        ...state,
        in_ajax:true
      }
    case 'USER_LOGIN_FAIL':
      return {
        ...state,
        in_ajax:false
      }
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        ...action.payload,
        is_login:true,
        in_ajax:false
      }
    case 'USER_LOGOUT':
      return {
        ...state,
        in_ajax:true
      }
    case 'USER_LOGOUT_FAIL':
      return {
        ...state,
        in_ajax:false
      }
    case 'USER_LOGOUT_SUCCESS':
      return {
        ...state,
        ...action.payload,
        is_login:false,
        in_ajax:false
      }
    case 'USER_AUTO_LOGIN':
      return {
        ...state,
        in_ajax:true
      }
    case 'USER_AUTO_LOGIN_FAIL':
      return {
        ...state,
        in_ajax:false,
        is_auto_login:true,
      }
    case 'USER_AUTO_LOGIN_SUCCESS':
      return {
        ...state,
        ...action.payload,
        is_login:true,
        in_ajax:false,
        is_auto_login:true,
      }
    default:
      return state
  }


}