import React, { Component, PropTypes } from 'react';

class List extends Component {
  static propTypes = {
    // className: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  create_html( html ){
    return { __html:html }
  }


  render() {

    return (
      <div className="test_list">
        <ul >
          { this.props.list.map( i => {
            return (<li key={i.key}>
              <div className="head">
                <em className="key">{i.id} - {i.key}</em>
                <div className="btns">
                  <button onClick={ e => { this.props.show_change_note(i,e) }}>注释</button>
                  <button className={ i.is_fav ? 'active' : '' } onClick={ e => { this.props.toggle_fav(i,e) }}>收藏</button>
                </div>
              </div>
              { i.image && <div className="test_image" ><img src={`/images/tests/${i.image}`}/></div> }
              <h2>{i.question}</h2>
              { this.props.full_mode ? (
                <ul className="options">
                  { i.options.map( (option,idx) => <li key={idx}>{option}</li> ) }
                </ul>
              ) : (
                <h3>{i.answer}</h3>
              ) }

              { i._edit_mode ? (
                <div className="note_edit">
                  <h4>注释：</h4>
                  <textarea onChange={ e => { this.props.change_note(i,e) } } value={i.note} rows="10" ></textarea>
                  <button onClick={ e => { this.props.on_change_note(i,e) } } >提交</button>
                </div>
              ) : ( i.note && <div className="note"><h4>注释：</h4>
                    <p dangerouslySetInnerHTML={this.create_html(i.note.split("\n").join('<br>'))}></p></div> )
              }

            </li>)
            } )
          }
        </ul>
      </div>
    );
  }
}

export default List;
