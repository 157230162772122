import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import store from '../../store.js'
import MaskActions from './mask.js'

const show = function (config) {
  // console.log('config')
  // console.log(JSON.stringify(config))
  // console.log(config)
  return dispatch => {
    const cur_uuid = _.get(store.getState(), 'dialog.uuid')
    if (cur_uuid) {
      dispatch(MaskActions.pop(cur_uuid))
    }
    config.uuid = uuidv4();
    config.type = _.get(config, 'type', 'dialog');
    dispatch(MaskActions.push(config.uuid));
    dispatch({ type: 'DIALOG_SHOW', payload: config })
  }
}

const hide = function () {
  return dispatch => {
    const cur_uuid = _.get(store.getState(), 'dialog.uuid')
    if (cur_uuid) {
      dispatch(MaskActions.pop(cur_uuid))
    }
    dispatch({ type: 'DIALOG_HIDE' })
  }
}

export default { show, hide }
