import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import _ from 'loadsh'

import UserAction from '../redux/actions/user.js'
import RedirectAction from '../redux/actions/redirect.js'
import ToastAction from '../redux/actions/toast.js'
import { Button, Input } from '../widgets/form/index.js'


function msp(state) {
  return {
    user: state.user,
    redirect: state.redirect
  };
}

function mdp(dispatch) {
  return {
    login: (username, password, from_path) => {
      username = _.trim(username)
      password = _.trim(password)
      if (username && password) {
        dispatch(UserAction.login(username, password, from_path))
      } else {
        dispatch(ToastAction.toast('请输入用户名和密码', 'error'))
      }
    },
    auto_login: (from_path) => {
      dispatch(UserAction.auto_login(from_path))
    },
    redirect_reset: () => {
      dispatch(RedirectAction.redirect_reset())
    }
  }
}

export class LoginPanel extends React.Component {
  static propTypes = {
    // name: React.PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: 'demo',
      password: 'demo',
    }
  }

  componentDidMount() {
    if (this.props.redirect) {
      this.props.redirect_reset();
    }
  }

  componentDidUpdate() {
    if (this.props.redirect) {
      this.props.redirect_reset();
    }
  }

  btn_login_click() {
    const from_path = _.has(this.props, 'location.state.from') ? this.props.location.state.from : ''
    this.props.login(this.state.username, this.state.password, from_path)
  }

  fe_change(e) {
    const tag = e.currentTarget
    const tag_name = e.currentTarget.tagName
    if (~['INPUT', 'SELECT', 'TEXTAREA'].indexOf(tag_name)) {
      const name = tag.name
      if (name) {
        const next_state = {
          ...this.state
        }
        next_state[name] = tag.value
        this.setState(next_state)
      }
    }
  }

  render() {
    let show_cls = ''
    if (this.props.user.is_auto_login) {
      show_cls = ' show'
    }

    let panel = null;

    if (this.props.redirect) {
      panel = <Redirect to={this.props.redirect} />
    } else {
      panel = (
        <div className={'box login_panel' + show_cls}>
          <form>
            <Input icon="user" className="md" placeholder="请输入用户名" type="text" name="username" value={this.state.username} onChange={this.fe_change.bind(this)} />
            <Input icon="key" className="md" placeholder="请输入密码" type="password" name="password" value={this.state.password} onChange={this.fe_change.bind(this)} />
            <Button className="btn md primary" in_ajax={!!this.props.user.in_ajax} onClick={this.btn_login_click.bind(this)}>登录</Button>
          </form>
        </div>
      )
    }
    return panel
  }
}

export default connect(
  msp, mdp
)(LoginPanel)
