import _ from 'lodash'

export default ( form_obj,key,params={} ) => ( new Promise( (rsl,rej) => {
  let val = form_obj[key];
  val = typeof val === 'string' ? _.trim(val) : val; 
  switch( typeof val ){
    case 'object':
      if( _.isEmpty(val) ){
        rej({err_code:'required'});
      }
      break;
    case 'boolean':
    case 'number':
      rsl(val);
    default:
      if( !val ){
        rej({err_code:'required'});
      }
  }
  rsl(val);
} ) );