//role
import fa_role from './role/form_add.js'
import fe_role from './role/form_edit.js'
import li_role from './role/list.js'

//user
import fa_user from './user/form_add.js'
import fe_user from './user/form_edit.js'
import li_user from './user/list.js'

//permission
import fa_permission from './permission/form_add.js'
import fe_permission from './permission/form_edit.js'
import li_permission from './permission/list.js'
import fl_permission from './permission/filters.js'


//file
import fa_file from './file/form_add.js'
import fe_file from './file/form_edit.js'
import li_file from './file/list.js'


//graph
import fa_graph from './graph/form_add.js'
import fe_graph from './graph/form_edit.js'
import li_graph from './graph/list.js'


//craft
import fa_craft from './craft/form_add.js'
import fe_craft from './craft/form_edit.js'
import li_craft from './craft/list.js'


//order
import fa_order from './order/form_add.js'
import fe_order from './order/form_edit.js'
import li_order from './order/list.js'


//product
import fa_product from './product/form_add.js'
import fe_product from './product/form_edit.js'
import li_product from './product/list.js'


//suite
import fa_suite from './suite/form_add.js'
import fe_suite from './suite/form_edit.js'
import li_suite from './suite/list.js'


//wc_option
import fa_wc_option from './wc_option/form_add.js'
import fe_wc_option from './wc_option/form_edit.js'
import li_wc_option from './wc_option/list.js'


//wc_menu
import fa_wc_menu from './wc_menu/form_add.js'
import fe_wc_menu from './wc_menu/form_edit.js'
import li_wc_menu from './wc_menu/list.js'




let object_config = {}

//role
object_config['role'] = {
  add:fa_role,
  edit:fe_role,
  list:li_role,
}

//user
object_config['user'] = {
  add:fa_user,
  edit:fe_user,
  list:li_user,
}

//permission
object_config['permission'] = {
  add:fa_permission,
  edit:fe_permission,
  list:li_permission,
  filters:fl_permission,
}

//file
object_config['file'] = {
  add:fa_file,
  edit:fe_file,
  list:li_file,
}

//graph
object_config['graph'] = {
  add:fa_graph,
  edit:fe_graph,
  list:li_graph,
}

//craft
object_config['craft'] = {
  add:fa_craft,
  edit:fe_craft,
  list:li_craft,
}

//order
object_config['order'] = {
  add:fa_order,
  edit:fe_order,
  list:li_order,
}

//product
object_config['product'] = {
  add:fa_product,
  edit:fe_product,
  list:li_product,
}

//suite
object_config['suite'] = {
  add:fa_suite,
  edit:fe_suite,
  list:li_suite,
}

//suite
object_config['wc_option'] = {
  add:fa_wc_option,
  edit:fe_wc_option,
  list:li_wc_option,
}

//suite
object_config['wc_menu'] = {
  add:fa_wc_menu,
  edit:fe_wc_menu,
  list:li_wc_menu,
}

export default object_config
