import _ from 'lodash'

export default ( state = {
    object : '',
    criteria : [],
    page : 1,
    size : 10,
    order : {},
    list : [],
    total_count: 0,
    total_page: 0,
    current_page: 0,
    options: {}
  }, action )=>{
  
  let new_state = {...state}

  console.log(action.type)

  switch ( action.type ){
    case 'OBJ_LIST':
      new_state.object = action.payload.object
      new_state.in_ajax = true
      return new_state
    case 'OBJ_LIST_SUCCESS':
      let page_settings = _.pick( action.payload,['total_count','total_page','current_page','size'] )
      page_settings = _.mapValues( page_settings, i => parseInt(i) )
      page_settings.order = action.payload.order
      new_state = {...new_state,...page_settings}
      new_state.page = page_settings.current_page

      new_state.list = action.payload.content || []
      new_state.options = _.pick( action.payload,['status','type'] )

      new_state.in_ajax = false
      return new_state
    case 'OBJ_LIST_FAIL':
      new_state.in_ajax = false
      return new_state
    default:
      return state
  }
}

